import React, { Component } from "react";
import Linkify from "react-linkify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class BroadcastItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    statusModal: false,
    editModal: false,
    EditBroadcast: {
      subject: this.props.item.subject,
      message: this.props.item.message,
      sender: this.props.item.sender,
      link_title: this.props.item.link_title,
    },
  };
  setInputEditBroadcast = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      EditBroadcast: {
        ...prevState.EditBroadcast,
        [name]: value,
      },
    }));
  };
  handleEditBroadcast = () => {
    const EM = this.state.EditBroadcast;

    this.EditBroadcast(
      this.props.item.BM_ID,
      EM.subject,
      EM.message,
      EM.sender,
      EM.link_title
    );
  };
  handleClose = () => {
    this.setState({ editModal: false });
  };
  handleOpen = () => {
    this.setState({ editModal: true });
  };
  handleCloseSM = () => {
    this.setState({ statusModal: false });
  };
  handleOpenSM = () => {
    this.setState({ statusModal: true });
  };
  EditBroadcast = async (id, s, m, sender, l) => {
    const get = await fetch("https://managerapi.the369.xyz/EditBroadcast", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        BM_ID: id,
        subject: s,
        message: m,
        sender: sender,
        link_title: l,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      //   alert("successfully Changed.");
      this.handleClose();
    }
    if (get.status === "failed") {
    }
  };

  RestoreBroadcast = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/RestoreBroadcast", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        BM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  DeleteBroadcast = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/DeleteBroadcast", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        BM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };

  timeGenarator = (t) => {
    const [date, time] = t.split("T");
    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");
    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <span className="col-2 right-border text-start px-2">
          {this.props.item.subject}
        </span>
        <span className="col-5 right-border text-start px-2 new-line">
          <Linkify
            componentDecorator={(decoratedHref) => (
              <a
                target="blank"
                href={decoratedHref}
                className=" not-underline msg-link bg-info mt-1"
              >
                {this.props.item.link_title}
              </a>
            )}
          >
            {this.props.item.message}
          </Linkify>
        </span>
        <span className="col-2 right-border text-start px-2">
          {this.props.item.sender}
        </span>
        <span className="col-1 right-border text-center px-2">
          <i
            onClick={this.handleOpenSM}
            className="bi bi-search text-success fs-5 c-pointer position-relative"
          >
            {this.props.broadcastStatus[this.props.item.BM_ID] !== undefined ? (
              this.props.broadcastStatus[this.props.item.BM_ID].length !== 0 ? (
                <>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success fs-8 mt-2 ms-2">
                    {this.props.broadcastStatus[this.props.item.BM_ID].length}
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {/* {this.props.broadcastStatus[this.props.item.BM_ID].length !== 0 ? (
              <>
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success fs-8 mt-2 ms-2">
                  {this.props.broadcastStatus[this.props.item.BM_ID].length}
                  <span className="visually-hidden">unread messages</span>
                </span>
              </>
            ) : (
              <></>
            )} */}
          </i>
        </span>
        <span className="col-1 right-border  text-start px-2 text-muted fs-8">
          {this.timeGenarator(this.props.item.Time)}
        </span>
        <span className="col-1   text-start px-2 text-center fs-8">
          {this.props.item.deleted === 1 ? (
            <>
              <i
                onClick={() => {
                  this.RestoreBroadcast(this.props.item.BM_ID);
                }}
                title="Restore"
                className="bi bi-arrow-clockwise fs-6 text-success c-pointer me-2 "
              ></i>
            </>
          ) : (
            <>
              <i
                onClick={() => {
                  this.DeleteBroadcast(this.props.item.BM_ID);
                }}
                title="Delete"
                className="bi bi-trash3 fs-6 text-danger c-pointer me-2"
              ></i>
            </>
          )}

          <i
            onClick={this.handleOpen}
            title="Edit"
            className="bi bi-pencil fs-6 text-white c-pointer "
          ></i>
        </span>

        <Modal
          show={this.state.editModal}
          onHide={() => {
            this.handleClose();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                edit message : {this.props.item.subject}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex flex-column p-3">
              <input
                id="sender"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Sender :${this.props.item.sender}`}
                onChange={this.setInputEditBroadcast}
                name="sender"
              />
              <input
                id="subject"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Subject :${this.props.item.subject}`}
                onChange={this.setInputEditBroadcast}
                name="subject"
              />
              <textarea
                type="text"
                className=" form-control form-control-xl bg-dark text-white border-success form-signup ms-2"
                id="message"
                onChange={this.setInputEditBroadcast}
                name="message"
                placeholder={`Message :${this.props.item.message}`}
                required
              ></textarea>
              <input
                id="link_title"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Link Titele :${this.props.item.link_title}`}
                onChange={this.setInputEditBroadcast}
                name="link_title"
              />
              <button
                id="btn"
                onClick={this.handleEditBroadcast}
                className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 mt-auto mb-5"
              >
                Edit
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.statusModal}
          onHide={() => {
            this.handleCloseSM();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                status message : {this.props.item.subject}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 row  p-3">
              {this.props.broadcastStatus[this.props.item.BM_ID] !==
              undefined ? (
                this.props.broadcastStatus[this.props.item.BM_ID].map(
                  (item) => {
                    return (
                      <>
                        <a
                          className="col-6 col-sm-4 col-md-3 col-lg-2 not-underline text-white p-2 bg-success m-2 text-center"
                          href={`https://twitter.com/${this.props.wtoT[item]}`}
                          target="_blank"
                        >
                          <i className="bi bi-twitter fs-6 text-info"></i>
                          {this.props.wtoT[item]}
                        </a>
                      </>
                    );
                  }
                )
              ) : (
                <>nobody read this message yet</>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleCloseSM();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default BroadcastItem;
