import React, { Component } from "react";

class Home extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div className="min-vh-100 work">
          <div className="d-flex justify-content-center align-self-center min-h-work">
            <img
              className="op-8 d-flex justify-content-center align-self-center max-h-work w-25"
              src="./asset/Home/logo.png"
              alt="AKUAKU"
            />
            {/* <video
              id="1"
              className="op-8 d-flex justify-content-center align-self-center max-h-work mw-100"
              muted
              loop
              autoPlay
              playsInline
            >
              Your browser does not support the video tag.
            </video> */}
          </div>
        </div>
      </>
    );
  }
}

export default Home;
