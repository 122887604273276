import React, { Component } from "react";

class InviteItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {};

  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  expireInviteKey = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/expireInviteKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        IC_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  availableInviteKey = async (id) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/availableInviteKey",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          IC_ID: id,
        }),
      }
    ).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 p-2">
          <div className="border border-1 p-1 text-white d-flex flex-column">
            <span className="d-flex fw-bold">
              {this.props.item.status === "available" ? (
                <>
                  <span className="text-white">{this.props.item.code}</span>
                </>
              ) : (
                <></>
              )}
              {this.props.item.status === "used" ? (
                <>
                  <span className="text-success">{this.props.item.code}</span>
                </>
              ) : (
                <></>
              )}
              {this.props.item.status === "expired" ? (
                <>
                  <span className="text-danger">{this.props.item.code}</span>
                </>
              ) : (
                <></>
              )}{" "}
              {this.props.item.status === "available" ? (
                <>
                  <i
                    onClick={() => {
                      this.expireInviteKey(this.props.item.IC_ID);
                    }}
                    title="EXPIRE"
                    className="bi bi-x-lg fs-6 text-danger c-pointer me-2 ms-auto"
                  ></i>
                </>
              ) : (
                <></>
              )}
              {this.props.item.status === "expired" ? (
                <>
                  <i
                    onClick={() => {
                      this.availableInviteKey(this.props.item.IC_ID);
                    }}
                    title="AVAILABLE"
                    className="bi bi-check2 fs-6 text-success c-pointer me-2 ms-auto"
                  ></i>
                </>
              ) : (
                <></>
              )}
            </span>
            <span className="d-flex justify-content-evenly fs-7">
              {this.props.item.signUpScore !== 0 ? (
                <>
                  <span>signUpScore: {this.props.item.signUpScore}</span>
                </>
              ) : (
                <></>
              )}
            </span>

            <span className="fs-8 text-muted">
              {this.timeGenarator(this.props.item.Time)}{" "}
              {this.props.item.user !== null ? (
                <>
                  <span className="fs-7 text-white ms-4">
                    <a
                      className="not-underline text-white"
                      href={`https://twitter.com/${
                        this.props.wtoT[this.props.item.user]
                      }`}
                      target="_blank"
                    >
                      <i className="bi bi-twitter fs-6 text-info"></i>
                      {this.props.wtoT[this.props.item.user]}
                    </a>
                  </span>
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default InviteItem;
