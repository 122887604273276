import React, { Component } from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class QuestItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    editModal: false,
    editQuest: {
      subject: this.props.item.subject,
      message: this.props.item.message,
      guide: this.props.item.guide,
      submission: this.props.item.submission,
      score: this.props.item.score,
      xp: this.props.item.xp,
      aku: this.props.item.pre_aku,
      uka: this.props.item.pre_uka,
      nft: this.props.item.pre_nft,
      pre_score: this.props.item.pre_score,
      answertype:
        this.props.item.answer_type === null
          ? "null"
          : this.props.item.answer_type,
      SAP: this.props.item.show_after_pass,
    },
  };
  setInputEditQuest = (e) => {
    const { name, value } = e.target;
    // if (name === "answertype" && value === "null") {
    //   this.setState((prevState) => ({
    //     editQuest: {
    //       ...prevState.editQuest,
    //       [name]: null,
    //     },
    //   }));
    // } else {
    //   this.setState((prevState) => ({
    //     editQuest: {
    //       ...prevState.editQuest,
    //       [name]: value,
    //     },
    //   }));
    // }
    this.setState((prevState) => ({
      editQuest: {
        ...prevState.editQuest,
        [name]: value,
      },
    }));
  };
  editDailyQuest = async (a) => {
    const get = await fetch("https://managerapi.the369.xyz/editDailyQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        a: a,
        DQ_ID: this.props.item.DQ_ID,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      // alert("Successfully changed.");
      setTimeout(() => {
        this.handleClose();
      }, 500);
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  editWeeklyQuest = async (a) => {
    const get = await fetch("https://managerapi.the369.xyz/editWeeklyQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        a: a,
        WQ_ID: this.props.item.WQ_ID,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      // alert("Successfully changed.");
      setTimeout(() => {
        this.handleClose();
      }, 500);
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  editSpecialQuest = async (a) => {
    const get = await fetch("https://managerapi.the369.xyz/editSpecialQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        a: a,
        SQ_ID: this.props.item.SQ_ID,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      // alert("Successfully changed.");
      setTimeout(() => {
        this.handleClose();
      }, 500);
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  handleEditQuest = () => {
    console.table(this.state.editQuest);
    console.log(this.props.item);
    if (this.props.type === "daily") {
      this.editDailyQuest(this.state.editQuest);
    }
    if (this.props.type === "weekly") {
      this.editWeeklyQuest(this.state.editQuest);
    }
    if (this.props.type === "special") {
      this.editSpecialQuest(this.state.editQuest);
    }
  };
  handleClose = () => {
    this.setState({ editModal: false });
  };
  handleOpen = () => {
    this.setState({
      editQuest: {
        subject: this.props.item.subject,
        message: this.props.item.message,
        guide: this.props.item.guide,
        submission: this.props.item.submission,
        score: this.props.item.score,
        xp: this.props.item.xp,
        aku: this.props.item.pre_aku,
        uka: this.props.item.pre_uka,
        nft: this.props.item.pre_nft,
        pre_score: this.props.item.pre_score,
        answertype:
          this.props.item.answer_type === null
            ? "null"
            : this.props.item.answer_type,
        SAP: this.props.item.show_after_pass,
      },
    });
    this.setState({ editModal: true });
  };
  handleDisableQuest = () => {
    if (this.props.item.disabled === 1) {
      if (this.props.type === "daily") {
        this.activeDailyQuest(this.props.item.DQ_ID);
      }
      if (this.props.type === "weekly") {
        this.activeWeeklyQuest(this.props.item.WQ_ID);
      }
      if (this.props.type === "special") {
        this.activeSpecialQuest(this.props.item.SQ_ID);
      }
    } else {
      if (this.props.type === "daily") {
        this.disableDailyQuest(this.props.item.DQ_ID);
      }
      if (this.props.type === "weekly") {
        this.disableWeeklyQuest(this.props.item.WQ_ID);
      }
      if (this.props.type === "special") {
        this.disableSpecialQuest(this.props.item.SQ_ID);
      }
    }
  };
  disableDailyQuest = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/disableDailyQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        DQ_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  disableWeeklyQuest = async (id) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/disableWeeklyQuest",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          WQ_ID: id,
        }),
      }
    ).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  disableSpecialQuest = async (id) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/disableSpecialQuest",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          SQ_ID: id,
        }),
      }
    ).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  activeDailyQuest = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/activeDailyQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        DQ_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  activeWeeklyQuest = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/activeWeeklyQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        WQ_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  activeSpecialQuest = async (id) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/activeSpecialQuest",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          SQ_ID: id,
        }),
      }
    ).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  render() {
    const item = this.props.item;
    const type = this.props.type;
    // console.log("dis : ", this.state.disabled);

    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <div className="col-12     text-center mb-2 ">
          <div className="L-card border border-1">
            <div
              className={
                item.disabled === 1
                  ? "bg-dark  w-100 d-flex text-danger justify-content-evenly p-1"
                  : "bg-dark  w-100 d-flex justify-content-evenly p-1"
              }
            >
              <span>{item.subject}</span>
              <span>XP:{item.xp}</span>
              <span>SCORE:{item.score}</span>
              <i
                onClick={this.handleOpen}
                className="bi bi-pencil fs-6 c-pointer text-white"
              ></i>
              <BootstrapSwitchButton
                onChange={this.handleDisableQuest}
                checked={item.disabled === 0 ? true : false}
                size="xs"
              />{" "}
            </div>
            <div className="bg-dark  w-100 d-flex flex-column justify-content-evenly">
              <div
                className="accordion "
                id={`moreQuestBase${type}${this.props.index}`}
              >
                <div className="accordion-item border border-0 ">
                  <h2
                    className="accordion-header "
                    id="panelsStayOpen-headingTwo"
                  >
                    <button
                      className="accordion-button bg-black text-white collapsed border border-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#moreQuest${type}${this.props.index}`}
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Show Quest
                    </button>
                  </h2>
                  <div
                    id={`moreQuest${type}${this.props.index}`}
                    className="accordion-collapse collapse bg-black"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div className="accordion-body d-flex flex-column text-white bg-dark ">
                      <span className="d-flex justify-content-evenly">
                        <span className="me-5 text-danger">Pre needs: </span>
                        <span>AKU: {item.pre_aku}</span>
                        <span>UKA: {item.pre_uka}</span>
                        <span>SCORE: {item.pre_score}</span>
                        <span>NFT: {item.pre_nft}</span>
                      </span>
                      <span className="text-start">
                        <span className="text-muted">MISSION:</span>{" "}
                        {item.message}
                      </span>
                      <span className="text-start">
                        <span className="text-muted">GUIDE:</span> {item.guide}
                      </span>

                      <span className="text-start">
                        <span className="text-muted">SUBMISSION:</span>{" "}
                        {item.submission}
                      </span>
                      <span className="text-start">
                        {this.props.type === "special" ? (
                          <>
                            {item.show_after_pass === 1 ? (
                              <>
                                <span className="text-success">
                                  SHOW AFTER PASS
                                </span>
                              </>
                            ) : (
                              <>
                                <span className="text-danger">
                                  DON'T SHOW AFTER PASS
                                </span>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </span>
                      <span className="text-center">
                        Answer Type:{" "}
                        {item.answer_type === null ? "Empty" : item.answer_type}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.editModal}
          onHide={() => {
            this.handleClose();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                edit Quest : {item.subject}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex flex-column p-3">
              <input
                id="subject"
                className="form-control form-control-sm ms-2 w-300 border-success bg-dark text-white mt-1 "
                type="text"
                placeholder={`subject: ${item.subject}`}
                onChange={this.setInputEditQuest}
                name="subject"
              />
              <textarea
                type="text"
                className=" form-control form-control-xl bg-dark text-white border-success form-signup w-80 ms-2 mt-1"
                id="message"
                onChange={this.setInputEditQuest}
                name="message"
                placeholder={`mission: ${item.message}`}
                required
              ></textarea>
              <textarea
                type="text"
                className=" form-control form-control-xl bg-dark text-white border-success form-signup w-80 ms-2 mt-1"
                id="guide"
                onChange={this.setInputEditQuest}
                name="guide"
                placeholder={`Guide: ${item.guide}`}
                required
              ></textarea>
              <textarea
                type="text"
                className=" form-control form-control-xl bg-dark text-white border-success form-signup w-80 ms-2 mt-1"
                id="submission"
                onChange={this.setInputEditQuest}
                name="submission"
                placeholder={`Submission: ${item.submission}`}
                required
              ></textarea>
              <span className="d-flex mt-3">
                <label className="mt-1 me-2 fw-bolder ms-2">SCORE:</label>
                <input
                  onChange={this.setInputEditQuest}
                  id="score"
                  className="form-control form-control-sm   border-success bg-dark text-white"
                  type="number"
                  name="score"
                  placeholder={item.score}
                />
                <label className="mt-1 me-2 fw-bolder ms-1">XP:</label>
                <input
                  onChange={this.setInputEditQuest}
                  id="xp"
                  className="form-control form-control-sm   border-success bg-dark text-white me-5"
                  type="number"
                  name="xp"
                  placeholder={item.xp}
                />
              </span>
              <span className="d-flex mt-3">
                <label className="mt-1 me-2 text-success fw-bolder ms-2">
                  AKU:
                </label>
                <input
                  onChange={this.setInputEditQuest}
                  id="aku"
                  className="form-control form-control-sm   border-success bg-dark text-white"
                  type="number"
                  name="aku"
                  placeholder={item.pre_aku}
                />
                <label className="mt-1 me-2 text-success fw-bolder ms-1">
                  UKA:
                </label>
                <input
                  onChange={this.setInputEditQuest}
                  id="uka"
                  className="form-control form-control-sm   border-success bg-dark text-white "
                  type="number"
                  name="uka"
                  placeholder={item.pre_uka}
                />
                <label className="mt-1 me-2 text-success fw-bolder ms-1">
                  NFT:
                </label>
                <input
                  onChange={this.setInputEditQuest}
                  id="nft"
                  className="form-control form-control-sm   border-success bg-dark text-white "
                  type="number"
                  name="nft"
                  placeholder={item.pre_nft}
                />
                <label className="mt-1 me-2 text-success fw-bolder ms-1 fs-8">
                  score NEED:
                </label>
                <input
                  onChange={this.setInputEditQuest}
                  id="pre_score"
                  className="form-control form-control-sm   border-success bg-dark text-white me-5"
                  type="number"
                  name="pre_score"
                  placeholder={item.pre_score}
                />
              </span>
              <div className="d-flex justify-content-evenly mt-3">
                <div className="form-check">
                  <input
                    onChange={this.setInputEditQuest}
                    className="form-check-input"
                    type="radio"
                    name="answertype"
                    id="link"
                    value="link"
                    checked={this.state.editQuest.answertype === "link"}
                  />
                  <label className="form-check-label" for="link">
                    Link
                  </label>
                </div>
                <div className="form-check">
                  <input
                    onChange={this.setInputEditQuest}
                    className="form-check-input"
                    type="radio"
                    name="answertype"
                    id="text"
                    value="text"
                    checked={this.state.editQuest.answertype === "text"}
                  />
                  <label className="form-check-label" for="text">
                    Text
                  </label>
                </div>
                <div className="form-check">
                  <input
                    onChange={this.setInputEditQuest}
                    className="form-check-input"
                    type="radio"
                    name="answertype"
                    id="image"
                    value="img"
                    checked={this.state.editQuest.answertype === "img"}
                  />
                  <label className="form-check-label" for="image">
                    Image
                  </label>
                </div>

                <div className="form-check">
                  <input
                    onChange={this.setInputEditQuest}
                    className="form-check-input"
                    type="radio"
                    name="answertype"
                    id="null"
                    value="null"
                    checked={this.state.editQuest.answertype === "null"}
                  />
                  <label className="form-check-label" for="null">
                    Empty
                  </label>
                </div>
              </div>
              {this.props.type === "special" ? (
                <>
                  <div className="d-flex justify-content-evenly mt-3">
                    <div className="form-check">
                      <input
                        onChange={this.setInputEditQuest}
                        className="form-check-input"
                        type="radio"
                        name="SAP"
                        id="image"
                        value={1}
                        checked={this.state.editQuest.SAP === 1}
                      />
                      <label className="form-check-label" for="image">
                        Show After Pass
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        onChange={this.setInputEditQuest}
                        className="form-check-input"
                        type="radio"
                        name="SAP"
                        id="null"
                        value={0}
                        checked={this.state.editQuest.SAP === 0}
                      />
                      <label className="form-check-label" for="null">
                        Don't Show After Pass
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <span className="text-center w-100 mt-3">
                <button
                  onClick={this.handleEditQuest}
                  className="btn btn-sm btn-success ms-auto me-auto d-flex mt-3"
                >
                  Edit
                </button>
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default QuestItem;
