import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import NotifItem from "./NotifItem";
import InboxMessageItem from "./InboxMessageItem";
import TReq from "./TReq";
import MessageItem from "./MessageItem";
import NFTUserItem from "./NFTUserItem";
import MissionsLog from "./MissionsLog";

class Lcard extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    sendNotif: { xp: 0, score: 0, subject: "", message: "" },
    sendMessage: { subject: "", message: "", sender: "", link_title: "" },
    vk: false,
    wk: false,
    sendm: false,
    ssx: false,
    NFT: false,
    seem: false,
    message: false,
    notifModal: false,
    info: {
      oldwallet: this.props.item.wallet_address,
      wallet: this.props.item.wallet_address,
      username: this.props.item.username,
      Telegram_Id: this.props.item.Telegram_Id,
    },
    MT: { messageTo: null },
    notif: [],
  };
  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  updateUserData = async (ow, w, u, T) => {
    const get = await fetch("https://managerapi.the369.xyz/updateUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        old_wallet_address: ow,
        wallet_address: w,
        username: u,
        Telegram_Id: T,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      alert("Successfully changed.");
      return true;
    }
    if (get.status === "failed") {
      alert("Something wrong");
      return false;
    }
  };
  ATM = async (w) => {
    const get = await fetch("https://managerapi.the369.xyz/ATM", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  RTM = async (w) => {
    const get = await fetch("https://managerapi.the369.xyz/RTM", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  verifyTwitter = async (w) => {
    const get = await fetch("https://managerapi.the369.xyz/verifyTwitter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };

  unsuspend = async (w) => {
    const get = await fetch("https://managerapi.the369.xyz/unsuspend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  newMessageCounter = () => {
    var i = 0;
    this.props.inboxMessage.map((item) => {
      if (
        item.sender.toLowerCase() ===
          this.props.item.wallet_address.toLowerCase() &&
        item.status === "unread"
      ) {
        i++;
      } else {
      }
    });
    return i;
  };
  suspend = async (w) => {
    const get = await fetch("https://managerapi.the369.xyz/suspend", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  addScoreXp = async (w, xp, score) => {
    const get = await fetch("https://managerapi.the369.xyz/addScoreXp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,

        xp: xp,
        score: score,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      alert("Successfully changed.");
      this.handleClosessx();
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  insertNotif = async (s, m, xp, score, user) => {
    const get = await fetch("https://managerapi.the369.xyz/insertNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        subject: s,
        message: m,
        xp: xp,
        score: score,
        user: user,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      if (xp === 0 && score === 0) {
        document.getElementById("refresh").click();
        alert("Successfully changed.");
        this.handleClosessx();
      } else {
        this.addScoreXp(this.props.item.wallet_address, xp, score);
      }
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  sendMessage = async (s, m, sender, r, l) => {
    const get = await fetch("https://managerapi.the369.xyz/sendMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        subject: s,
        message: m,
        sender: sender,
        receiver: r,
        link_title: l,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      alert("Successfully changed.");
      this.handleClosesendm();
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  updateMessageTo = async (w, MT) => {
    const get = await fetch("https://managerapi.the369.xyz/updateMessageTo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        MT: MT,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      alert("Successfully changed.");
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };

  walletGenerator = (w) => {
    let newWallet = w.split("");

    let length = newWallet.length;

    let temp =
      newWallet[0] +
      newWallet[1] +
      newWallet[2] +
      newWallet[3] +
      newWallet[4] +
      "..." +
      newWallet[length - 4] +
      newWallet[length - 3] +
      newWallet[length - 2] +
      newWallet[length - 1];

    return temp;
  };
  setInputsendNotif = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      sendNotif: {
        ...prevState.sendNotif,
        [name]: value,
      },
    }));
  };
  setInputsendMessage = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      sendMessage: {
        ...prevState.sendMessage,
        [name]: value,
      },
    }));
  };
  setInputMessageTo = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      MT: {
        ...prevState.MT,
        [name]: value,
      },
    }));
  };
  setInputInfo = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      info: {
        ...prevState.info,
        [name]: value,
      },
    }));
  };
  handleSendNotif = () => {
    const SN = this.state.sendNotif;

    this.insertNotif(
      SN.subject,
      SN.message,
      parseInt(SN.xp),
      parseInt(SN.score),
      this.props.item.wallet_address
    );
  };
  nftCounter = () => {
    var i = 0;
    this.props.holders.map((item) => {
      if (
        item.wallet_address.toLowerCase() ===
        this.props.item.wallet_address.toLowerCase()
      ) {
        i++;
      }
    });
    return i;
  };
  handleSendMessage = () => {
    const SM = this.state.sendMessage;

    this.sendMessage(
      SM.subject,
      SM.message,
      SM.sender,
      this.props.item.wallet_address,
      SM.link_title
    );
  };
  handleAddMessageTo = () => {
    const MessageTo = this.state.MT.messageTo;

    this.updateMessageTo(this.props.item.wallet_address, MessageTo);
  };
  handleSaveInfo = async () => {
    const res = await this.updateUserData(
      this.state.info.oldwallet,
      this.state.info.wallet,
      this.state.info.username,
      this.state.info.Telegram_Id
    );
    if (res) {
      document.getElementById("refresh").click();
      document.getElementById("wallet").value = "";
    } else {
    }
  };
  handleClosevk = () => {
    this.setState({ vk: false });
  };
  handleOpenvk = () => {
    this.setState({ vk: true });
  };
  handleClosenotifModal = () => {
    this.setState({ notifModal: false });
  };
  handleOpennotifModal = () => {
    this.setState({ notifModal: true });
  };
  handleCloseMessage = () => {
    this.setState({ message: false });
  };
  handleOpenMessage = () => {
    this.setState({ message: true });
  };
  handleCloseNFT = () => {
    this.setState({ NFT: false });
  };
  handleOpenNFT = () => {
    this.setState({ NFT: true });
  };
  handleClosewk = () => {
    this.setState({ wk: false });
  };
  handleOpenwk = () => {
    this.setState({ wk: true });
  };
  handleClosesendm = () => {
    this.setState({ sendm: false });
  };
  handleOpensendm = () => {
    this.setState({ sendm: true });
  };
  handleClosessx = () => {
    this.setState({ ssx: false });
  };
  handleOpenssx = () => {
    this.setState({ ssx: true });
  };

  handleCloseseem = () => {
    this.setState({ seem: false });
  };
  handleOpenseem = () => {
    this.setState({ seem: true });
  };
  // refreshMissionLog = () => {

  //   // call api or anything

  //   var a = [];
  //   a.push(...this.props.dailyNotif);
  //   a.push(...this.props.weeklyNotif);
  //   a.push(...this.props.specialNotif);

  //   const sortByDate = (a) => {
  //     const sorter = (a, b) => {
  //       return (
  //         new Date(b.review_Time).getTime() - new Date(a.review_Time).getTime()
  //       );
  //     };
  //     a.sort(sorter);
  //   };
  //   sortByDate(a);

  //   console.table(a);
  //   this.setState({ notif: a });
  // };
  // componentDidMount() {
  //   this.refreshMissionLog();

  //   // // call api or anything

  //   // var a = [];
  //   // a.push(...this.props.dailyNotif);
  //   // a.push(...this.props.weeklyNotif);
  //   // a.push(...this.props.specialNotif);

  //   // const sortByDate = (a) => {
  //   //   const sorter = (a, b) => {
  //   //     return (
  //   //       new Date(b.review_Time).getTime() - new Date(a.review_Time).getTime()
  //   //     );
  //   //   };
  //   //   a.sort(sorter);
  //   // };
  //   // sortByDate(a);

  //   // console.table(a);
  //   // this.setState({ notif: a });
  // }
  render() {
    var a = [];
    a.push(...this.props.dailyNotif);
    a.push(...this.props.weeklyNotif);
    a.push(...this.props.specialNotif);
    const sortByDate = (a) => {
      const sorter = (a, b) => {
        return (
          new Date(b.review_Time).getTime() - new Date(a.review_Time).getTime()
        );
      };
      a.sort(sorter);
    };
    sortByDate(a);
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>{" "}
        <div
          // onClick={() => {
          //   this.handleOpen();
          // }}
          className="col-12  col-md-6   text-center mb-2 "
        >
          <div className="L-card border border-1">
            <div
              className={
                this.props.tReqNotif.hasOwnProperty(
                  this.props.item.wallet_address
                )
                  ? "bg-danger  w-100 d-flex justify-content-evenly p-1"
                  : this.nftCounter() !== 0
                  ? "bg-success  w-100 d-flex justify-content-evenly p-1"
                  : "bg-dark  w-100 d-flex justify-content-evenly p-1"
              }
            >
              <h5
                className={
                  this.props.item.isSuspended === 1
                    ? " fs-6 fw-bolder m-0 mb-1 mt-1 text-danger"
                    : " fs-6 fw-bolder m-0 mb-1 mt-1 text-white"
                }
              >
                {this.walletGenerator(this.props.item.wallet_address)}
              </h5>

              <span className="badge mt-1">SCORE {this.props.item.score}</span>
              <span className="badge mt-1">
                XP{" "}
                <span className="text-white fw-bold">{this.props.item.xp}</span>
              </span>
              <span className="badge text-bg-dark me-1 position-relative">
                <a
                  className="not-underline text-white"
                  href={`https://twitter.com/${this.props.item.Twitter_Id}`}
                  target="_blank"
                >
                  <i className="bi bi-twitter fs-6 text-info"></i>{" "}
                  {this.props.item.Twitter_Id}
                </a>
                {this.newMessageCounter() !== 0 ? (
                  <>
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                      {this.newMessageCounter()}
                      <span className="visually-hidden">unread messages</span>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </span>
              {this.props.tReqNotif.hasOwnProperty(
                this.props.item.wallet_address
              ) ? (
                <>
                  <span className="fw-bolder fs-6 text-white">*</span>
                </>
              ) : (
                <></>
              )}
              {this.nftCounter() !== 0 ? (
                <>
                  <span className=" fs-7 text-white">
                    AKU {this.nftCounter()}
                  </span>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="bg-dark  w-100 d-flex flex-column justify-content-evenly">
              <div
                className="accordion "
                id={`moreNotifBase${this.props.item.user_id}`}
              >
                <div className="accordion-item border border-0 ">
                  <h2
                    className="accordion-header "
                    id="panelsStayOpen-headingTwo"
                  >
                    <button
                      className="accordion-button bg-black text-white collapsed border border-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#moreNotif${this.props.item.user_id}`}
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Show Actions
                    </button>
                  </h2>
                  <div
                    id={`moreNotif${this.props.item.user_id}`}
                    className="accordion-collapse collapse bg-black"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div className="accordion-body d-flex flex-column text-white bg-dark ">
                      <div className="d-flex justify-content-evenly">
                        <span className="fw-bold fs-7 wallet">
                          {this.props.item.wallet_address}
                        </span>
                        {this.props.item.isSuspended === 1 ? (
                          <>
                            <button
                              onClick={() => {
                                this.unsuspend(this.props.item.wallet_address);
                              }}
                              className="btn btn-sm btn-success fs-7"
                            >
                              Unsuspend
                            </button>{" "}
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => {
                                this.suspend(this.props.item.wallet_address);
                              }}
                              className="btn btn-sm btn-danger fs-7"
                            >
                              Suspend
                            </button>{" "}
                          </>
                        )}
                      </div>
                      <div className="row justify-content-evenly ">
                        <div className="col-12 col-md-6  mt-2 p-1 ">
                          <div className="border border-1">
                            <input
                              onChange={this.setInputInfo}
                              id="wallet"
                              className="form-control form-control-sm m-2 w-80 border-success bg-dark text-white"
                              type="text"
                              name="wallet"
                              placeholder={`wallet: ${this.props.item.wallet_address}`}
                            />
                            <input
                              onChange={this.setInputInfo}
                              id="username"
                              className="form-control form-control-sm m-2 w-80 border-success bg-dark text-white"
                              type="text"
                              name="username"
                              placeholder={`Username: ${this.props.item.username}`}
                            />
                            <input
                              onChange={this.setInputInfo}
                              id="Telegram_Id"
                              name="Telegram_Id"
                              className="form-control form-control-sm m-2 w-80 border-success bg-dark text-white"
                              type="text"
                              placeholder={`Telegram ID: ${this.props.item.Telegram_Id}`}
                            />
                            <span className="fs-6 text-success" id="suc"></span>
                            <span className="fs-6 text-danger" id="err"></span>
                            <button
                              onClick={this.handleSaveInfo}
                              id="btn"
                              className="btn btn-sm btn-success align-self-start mb-2 fs-5"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mt-2 p-1 ">
                          <div className="row border border-1 h-100 p-1 overflow-auto h-175">
                            {this.props.notif.map((item) => {
                              if (
                                item.user.toLowerCase() ===
                                this.props.item.wallet_address.toLowerCase()
                              ) {
                                return (
                                  <>
                                    <NotifItem
                                      refreshAll={this.props.refreshAll}
                                      item={item}
                                    />
                                  </>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div className="col-12  mt-2 p-1 ">
                          <div className="row border border-1 h-100 d-flex justify-content-evenly p-1 max-h-100  overflow-auto">
                            <div className="col-12 history-item fs-7 d-flex justify-content-evenly ">
                              <span className=" fs-8 history-item-child text-center w-100 ">
                                From
                              </span>
                              <span className=" fs-8 history-item-child text-center w-100">
                                To
                              </span>
                              <span className="fs-8 history-item-child text-center  w-100">
                                Status
                              </span>
                              <span className="fs-8 history-item-child text-center w-100">
                                {" "}
                                Message
                              </span>{" "}
                              <span className="fs-8 history-item-child text-center w-100">
                                {" "}
                                Actions
                              </span>{" "}
                              <span className="fs-8 text-muted w-100 text-center">
                                Date
                              </span>
                            </div>
                            {this.props.tReq.map((item) => {
                              if (
                                item.user.toLowerCase() ===
                                this.props.item.wallet_address.toLowerCase()
                              ) {
                                return (
                                  <>
                                    <TReq item={item} />
                                  </>
                                );
                              }
                            })}
                          </div>
                        </div>
                        <div className="col-12  mt-2 p-1 ">
                          <div className="border border-1 h-100 d-flex justify-content-evenly p-1">
                            {this.props.item.AllowToMessage === 1 ? (
                              <>
                                <button
                                  onClick={() => {
                                    this.RTM(this.props.item.wallet_address);
                                  }}
                                  className="btn btn-sm btn-danger"
                                >
                                  RTM
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => {
                                    this.ATM(this.props.item.wallet_address);
                                  }}
                                  className="btn btn-sm btn-success"
                                >
                                  ATM
                                </button>
                              </>
                            )}

                            <input
                              onChange={this.setInputMessageTo}
                              id="messageTo"
                              className="form-control form-control-sm m-2 w-80 border-success bg-dark text-white"
                              type="text"
                              name="messageTo"
                              placeholder={`messageTo: ${this.props.item.messageTo}`}
                            />

                            <button
                              onClick={this.handleAddMessageTo}
                              className="btn btn-sm btn-success"
                            >
                              ADD
                            </button>
                          </div>
                        </div>
                        <div className="col-12  mt-2 p-1 ">
                          <div className="border border-1 h-100 d-flex justify-content-evenly p-1">
                            <button
                              onClick={this.handleOpenvk}
                              className="btn btn-sm btn-success"
                            >
                              Valid Keys
                            </button>
                            <button
                              onClick={this.handleOpenwk}
                              className="btn btn-sm btn-danger"
                            >
                              Wrong Keys
                            </button>
                            <button
                              onClick={this.handleOpensendm}
                              className="btn btn-sm btn-info"
                            >
                              Send Message
                            </button>
                            <button
                              onClick={this.handleOpenssx}
                              className="btn btn-sm btn-success"
                            >
                              Send Score/XP
                            </button>
                            <button
                              onClick={this.handleOpenseem}
                              className="btn btn-sm btn-info"
                            >
                              see messages
                            </button>
                            <button
                              onClick={this.handleOpennotifModal}
                              className="btn btn-sm btn-warning"
                            >
                              Missions log
                            </button>
                            <button
                              onClick={this.handleOpenMessage}
                              className="btn btn-sm btn-danger position-relative"
                            >
                              Message
                              {this.newMessageCounter() !== 0 ? (
                                <>
                                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                                    {this.newMessageCounter()}
                                    <span className="visually-hidden">
                                      unread messages
                                    </span>
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </button>
                            {this.nftCounter() !== 0 ? (
                              <>
                                <button
                                  onClick={this.handleOpenNFT}
                                  className="btn btn-sm btn-danger"
                                >
                                  NFT
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                            {this.props.item.twitter_verified === 0 ? (
                              <>
                                <button
                                  onClick={() => {
                                    this.verifyTwitter(
                                      this.props.item.wallet_address
                                    );
                                  }}
                                  className="btn btn-sm btn-success"
                                >
                                  verify Twitter
                                </button>
                              </>
                            ) : (
                              <></>
                            )}{" "}
                          </div>
                        </div>
                      </div>
                      <></>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.vk}
          onHide={() => {
            this.handleClosevk();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                Valid Keys for : {this.props.item.Twitter_Id}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              {this.props.validKey.map((item) => {
                if (
                  item.user.toLowerCase() ===
                  this.props.item.wallet_address.toLowerCase()
                ) {
                  return (
                    <>
                      <div className="col-6 col-sm-4 col-md-3 col-lg-2 p-2">
                        <div className="border border-1 p-1 text-success d-flex flex-column">
                          {item.keyword}{" "}
                          <span className="fs-8 text-muted">
                            {this.timeGenarator(item.Time)}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClosevk();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.notifModal}
          onHide={() => {
            this.handleClosenotifModal();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                MISSIONS LOG : {this.props.item.Twitter_Id}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {a.map((item, index) => {
              if (
                item.user.toLowerCase() ===
                this.props.item.wallet_address.toLowerCase()
              ) {
                return (
                  <div className="col-12 border border-1 bg-dark my-1 px-3 ms-2 ">
                    <MissionsLog
                      item={item}
                      wtoT={this.props.wtoT}
                      refreshAll={this.props.refreshAll}
                      type={"daily"}
                      index={index}
                    />
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClosenotifModal();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.NFT}
          onHide={() => {
            this.handleCloseNFT();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                NFT of : {this.props.item.Twitter_Id}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              {this.props.holders.map((item) => {
                if (
                  item.wallet_address.toLowerCase() ===
                  this.props.item.wallet_address.toLowerCase()
                ) {
                  return (
                    <>
                      <NFTUserItem item={item} />
                    </>
                  );
                }
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleCloseNFT();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.wk}
          onHide={() => {
            this.handleClosewk();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                wrong Keys for : {this.props.item.Twitter_Id}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              {this.props.wrongKey.map((item) => {
                if (
                  item.user.toLowerCase() ===
                  this.props.item.wallet_address.toLowerCase()
                ) {
                  return (
                    <>
                      <div className="col-6 col-sm-4 col-md-3 col-lg-2 p-2">
                        <div className="border border-1 p-1 text-danger d-flex flex-column">
                          {item.keyword}{" "}
                          <span className="fs-8 text-muted">
                            {this.timeGenarator(item.Time)}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClosewk();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.ssx}
          onHide={() => {
            this.handleClosessx();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                Send Score/Xp to : {this.props.item.Twitter_Id}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex flex-column p-3">
              <input
                id="ُsubject"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Subject...`}
                onChange={this.setInputsendNotif}
                name="subject"
              />

              <textarea
                type="text"
                className=" form-control form-control-xl bg-dark text-white border-success form-signup ms-2"
                id="message"
                onChange={this.setInputsendNotif}
                name="message"
                placeholder={`Message...`}
                required
              ></textarea>
              <input
                id="xp"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Xp (let it empty if don't need)`}
                onChange={this.setInputsendNotif}
                name="xp"
              />
              <input
                id="score"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Score (let it empty if don't need)`}
                onChange={this.setInputsendNotif}
                name="score"
              />
              <button
                id="btn"
                onClick={this.handleSendNotif}
                className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 mt-auto mb-5"
              >
                Send
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClosessx();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.seem}
          onHide={() => {
            this.handleCloseseem();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                see message we send to : {this.props.item.Twitter_Id}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-evenly d-flex">
              <div className="col-12  p-2">
                <div className=" p-1 text-white d-flex ">
                  <span className="col-2 right-border text-start px-2 fs-8">
                    Subject
                  </span>
                  <span className="col-5 right-border text-start px-2 fs-8">
                    Message
                  </span>
                  <span className="col-2 right-border text-start px-2 fs-8">
                    Sender
                  </span>
                  <span className="col-1 right-border text-start px-2 fs-8">
                    Status
                  </span>
                  <span className="col-1 right-border text-start px-2 fs-8">
                    Date
                  </span>
                  <span className="col-1  text-start px-2 fs-8">Actions</span>
                </div>
              </div>

              {this.props.SentMessages.map((item) => {
                if (
                  item.receiver.toLowerCase() ===
                  this.props.item.wallet_address.toLowerCase()
                ) {
                  return (
                    <>
                      <div className="col-12  p-2">
                        <div className="border border-1 p-1 text-white d-flex ">
                          <MessageItem
                            refreshAll={this.props.refreshAll}
                            item={item}
                          />
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleCloseseem();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.sendm}
          onHide={() => {
            this.handleClosesendm();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                send message to : {this.props.item.Twitter_Id}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex flex-column p-3">
              <input
                id="sender"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder="sender"
                onChange={this.setInputsendMessage}
                name="sender"
              />
              <input
                id="subject"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder="subject"
                onChange={this.setInputsendMessage}
                name="subject"
              />
              <textarea
                type="text"
                className=" form-control form-control-xl bg-dark text-white border-success form-signup ms-2"
                id="message"
                onChange={this.setInputsendMessage}
                name="message"
                placeholder="Message..."
                required
              ></textarea>
              <input
                id="link_title"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder="Link_title (if don't have link let it empty)"
                onChange={this.setInputsendMessage}
                name="link_title"
              />
              <span className="fs-6 text-success" id="suc"></span>
              <span className="fs-6 text-danger" id="err"></span>
              <button
                id="btn"
                onClick={this.handleSendMessage}
                className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 mt-auto mb-5"
              >
                Send
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClosesendm();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.message}
          onHide={() => {
            this.handleCloseMessage();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={true}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                message of : {this.props.item.Twitter_Id}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-evenly d-flex w-100 ms-1">
              <div className="col-12  p-2">
                <div className=" p-1 text-white d-flex  ">
                  <span className="col-2 right-border text-start px-2 fs-8">
                    Subject
                  </span>
                  <span className="col-5 right-border text-start px-2 fs-8">
                    Message
                  </span>
                  <span className="col-1 right-border text-start px-2 fs-8">
                    receiver
                  </span>
                  <span className="col-1 right-border text-start px-2 fs-8">
                    Sender
                  </span>
                  <span className="col-1 right-border text-start px-2 fs-8">
                    Status
                  </span>
                  <span className="col-1 right-border text-start px-2 fs-8">
                    Date
                  </span>
                  <span className="col-1  text-start px-2 fs-8">Actions</span>
                </div>
              </div>
              {this.newMessageCounter() !== 0 ? (
                <>
                  <div className="col-12 text-center fw-bold text-danger">
                    {this.newMessageCounter()} Unread Message
                  </div>
                </>
              ) : (
                <></>
              )}

              {this.props.inboxMessage.map((item) => {
                if (
                  item.sender.toLowerCase() ===
                  this.props.item.wallet_address.toLowerCase()
                ) {
                  return (
                    <>
                      <div className="col-12  p-2">
                        <div className="border border-1 p-1 text-white d-flex ">
                          <InboxMessageItem
                            wtoT={this.props.wtoT}
                            refreshAll={this.props.refreshAll}
                            item={item}
                          />
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleCloseMessage();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Lcard;
