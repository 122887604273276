import React, { Component } from "react";
class NFTItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  walletGenerator = (w) => {
    let newWallet = w.split("");

    let length = newWallet.length;

    let temp =
      newWallet[0] +
      newWallet[1] +
      newWallet[2] +
      newWallet[3] +
      newWallet[4] +
      "..." +
      newWallet[length - 4] +
      newWallet[length - 3] +
      newWallet[length - 2] +
      newWallet[length - 1];

    return temp;
  };
  render() {
    const checkMember =
      this.props.wtoT[this.props.item.wallet_address] === undefined;

    return (
      <>
        <div
          id="NFT"
          className="col-6 col-sm-4 col-md-3  col-lg-2 text-center "
        >
          <div>
            <div className="card bg-dark max-w-200 w-100 d-flex ms-auto me-auto ">
              <h5 className="card-title fs-6 fw-bolder m-0 mb-1 mt-1 text-white ">
                {/* {this.walletGenerator(this.props.item.wallet_address)} */}
              </h5>
              <img
                src={`./NFTImages/${this.props.item.token_id}.jpg`}
                className="card-img-top"
                alt="..."
              />

              <span
                title="Copy to clipboard"
                onClick={() => {
                  navigator.clipboard.writeText(this.props.item.wallet_address);
                }}
                className={
                  checkMember
                    ? "badge text-bg-danger mt-2 c-pointer"
                    : "badge text-bg-success mt-2 c-pointer"
                }
              >
                {this.walletGenerator(this.props.item.wallet_address)}
                <i className="bi bi-clipboard-check ms-3"></i>
              </span>
              <span className="badge mt-1 fs-6">
                AkuAku #{this.props.item.token_id}
              </span>
              <div className="card-body fs-6 p-0">
                <p className="card-text m-0 p-0">
                  {checkMember ? (
                    <>
                      <span className="invisible">Not member</span>
                    </>
                  ) : (
                    <>
                      <a
                        className="not-underline text-white"
                        href={`https://twitter.com/${
                          this.props.wtoT[this.props.item.wallet_address]
                        }`}
                        target="_blank"
                      >
                        <i className="bi bi-twitter fs-6 text-info"></i>
                        {this.props.wtoT[this.props.item.wallet_address]}
                      </a>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NFTItem;
