import React, { Component } from "react";
import { Table } from "react-bootstrap";
import QuestItem from "./QuestItem";
import QuestPending from "./QuestPending";

class Quests extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    daily: false,
    weekly: false,
    special: false,
    claimPoint: false,
    addQuest: false,
    quest: "",
    subQuest: {
      subject: "",
      message: "",
      guide: "",
      submission: "",
      score: 0,
      xp: 0,
      aku: 0,
      uka: 0,
      nft: 0,
      pre_score: 0,
      answertype: "",
      SAP: 1,
    },
  };
  setInputQuest = (e) => {
    const { name, value } = e.target;

    this.setState(() => ({
      quest: value,
    }));
  };
  setInputSubQuest = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      subQuest: {
        ...prevState.subQuest,
        [name]: value,
      },
    }));
  };
  addDailyQuest = async (a) => {
    const get = await fetch("https://managerapi.the369.xyz/addDailyQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        a: a,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      // alert("Successfully changed.");
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  addWeeklyQuest = async (a) => {
    const get = await fetch("https://managerapi.the369.xyz/addWeeklyQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        a: a,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      // alert("Successfully changed.");
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  addSpecialQuest = async (a) => {
    const get = await fetch("https://managerapi.the369.xyz/addSpecialQuest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        a: a,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      // alert("Successfully changed.");
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  handleSubQuest = () => {
    console.table(this.state.subQuest);
    if (this.state.quest === "daily") {
      this.addDailyQuest(this.state.subQuest);
    }
    if (this.state.quest === "weekly") {
      this.addWeeklyQuest(this.state.subQuest);
    }
    if (this.state.quest === "special") {
      this.addSpecialQuest(this.state.subQuest);
    }
  };
  refreshState = () => {
    this.setState({
      daily: false,
      weekly: false,
      special: false,
      claimPoint: false,
      addQuest: false,
    });
  };
  handleDaily = () => {
    this.refreshState();
    this.setState({ daily: true });
  };
  handleWeekly = () => {
    this.refreshState();
    this.setState({ weekly: true });
  };
  handleSpecial = () => {
    this.refreshState();
    this.setState({ special: true });
  };
  handleClaimPoint = () => {
    this.refreshState();
    this.setState({ claimPoint: true });
  };
  handleaddQuest = () => {
    this.refreshState();
    this.setState({ addQuest: true });
  };
  render() {
    return (
      <>
        <button
          id="update-btn"
          className="d-none"
          onClick={this.props.updateUser}
        ></button>
        <button
          id="ref"
          className="d-none"
          onClick={this.props.refreshQuests}
        ></button>
        <div className="min-vh-100 work bg-black">
          <div className="d-flex justify-content-center align-self-center min-h-work">
            <div className="row align-self-center g-1 w-100 h-100">
              <div className="col-12 col-md-2 d-flex flex-md-column min-h-quest-menu">
                <span
                  onClick={
                    !this.state.daily ? this.handleDaily : this.refreshState
                  }
                  class={
                    this.state.daily
                      ? "mt-3 px-2 w-fit   btn-scale  nav-active c-pointer  position-relative"
                      : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer  position-relative"
                  }
                >
                  Daily
                  {this.props.dailyPending.length !== 0 ? (
                    <>
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {this.props.dailyPending.length}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <span
                  onClick={
                    !this.state.weekly ? this.handleWeekly : this.refreshState
                  }
                  class={
                    this.state.weekly
                      ? "mt-3 px-2 w-fit  btn-scale c-pointer  nav-active  position-relative"
                      : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer  position-relative"
                  }
                >
                  Weekly
                  {this.props.weeklyPending.length !== 0 ? (
                    <>
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {this.props.weeklyPending.length}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <span
                  onClick={
                    !this.state.special ? this.handleSpecial : this.refreshState
                  }
                  class={
                    this.state.special
                      ? "mt-3 px-2 w-fit  btn-scale c-pointer  nav-active  position-relative"
                      : "mt-3 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer  position-relative"
                  }
                >
                  Once
                  {this.props.specialPending.length !== 0 ? (
                    <>
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        {this.props.specialPending.length}
                        <span className="visually-hidden">unread messages</span>
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </span>

                <span
                  onClick={
                    !this.state.addQuest
                      ? this.handleaddQuest
                      : this.refreshState
                  }
                  class={
                    this.state.addQuest
                      ? "mt-auto ms-auto ms-md-0 mb-0 mb-md-4 w-fit c-pointer  btn-scale nav-active"
                      : "mt-auto ms-auto ms-md-0 mb-0 mb-md-4 border border-1 border-white px-2 w-fit BR-5 c-pointer btn-scale ms-2 ms-md-0 c-pointer"
                  }
                >
                  <i className="bi bi-file-earmark-plus-fill fs-6 me-1"></i>Add
                  Quest
                </span>
              </div>
              <div className="col-12 col-md-10 px-2 quests pt-3">
                <div className="row align-self-center g-1 w-100 g-2">
                  <div className="col-12 col-lg-5 p-2">
                    {this.state.addQuest ? (
                      <>
                        <div className="border border-1 overflow-auto h-750 p-1 ">
                          <div className="text-center fs-4 fw-bold text-success">
                            Add{" "}
                            {this.state.quest === "special"
                              ? "once"
                              : this.state.quest}{" "}
                            Quest
                          </div>

                          <div className="d-flex justify-content-evenly">
                            <div className="form-check">
                              <input
                                onChange={this.setInputQuest}
                                className="form-check-input"
                                type="radio"
                                name="quest"
                                id="daily"
                                value="daily"
                              />
                              <label className="form-check-label" for="daily">
                                Daily
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                onChange={this.setInputQuest}
                                className="form-check-input"
                                type="radio"
                                name="quest"
                                id="weekly"
                                value="weekly"
                              />
                              <label className="form-check-label" for="weekly">
                                Weekly
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                onChange={this.setInputQuest}
                                className="form-check-input"
                                type="radio"
                                name="quest"
                                id="special"
                                value="special"
                              />
                              <label className="form-check-label" for="special">
                                Once
                              </label>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.quest === "" ? "disable-link" : ""
                            }
                          >
                            <input
                              id="subject"
                              className="form-control form-control-sm ms-2 w-300 border-success bg-dark text-white mt-1 "
                              type="text"
                              placeholder="subject"
                              onChange={this.setInputSubQuest}
                              name="subject"
                            />
                            <textarea
                              type="text"
                              className=" form-control form-control-xl bg-dark text-white border-success form-signup w-80 ms-2 mt-1"
                              id="message"
                              onChange={this.setInputSubQuest}
                              name="message"
                              placeholder="Message..."
                              required
                            ></textarea>
                            <textarea
                              type="text"
                              className=" form-control form-control-xl bg-dark text-white border-success form-signup w-80 ms-2 mt-1"
                              id="guide"
                              onChange={this.setInputSubQuest}
                              name="guide"
                              placeholder="Guide..."
                              required
                            ></textarea>
                            <textarea
                              type="text"
                              className=" form-control form-control-xl bg-dark text-white border-success form-signup w-80 ms-2 mt-1"
                              id="submission"
                              onChange={this.setInputSubQuest}
                              name="submission"
                              placeholder="Submission..."
                              required
                            ></textarea>
                            <span className="d-flex mt-3">
                              <label className="mt-1 me-2 fw-bolder ms-2">
                                SCORE:
                              </label>
                              <input
                                onChange={this.setInputSubQuest}
                                id="score"
                                className="form-control form-control-sm   border-success bg-dark text-white"
                                type="number"
                                name="score"
                                placeholder={`0`}
                              />
                              <label className="mt-1 me-2 fw-bolder ms-1">
                                XP:
                              </label>
                              <input
                                onChange={this.setInputSubQuest}
                                id="xp"
                                className="form-control form-control-sm   border-success bg-dark text-white me-5"
                                type="number"
                                name="xp"
                                placeholder={`0`}
                              />
                            </span>
                            <span className="d-flex mt-3">
                              <label className="mt-1 me-2 text-success fw-bolder ms-2">
                                AKU:
                              </label>
                              <input
                                onChange={this.setInputSubQuest}
                                id="aku"
                                className="form-control form-control-sm   border-success bg-dark text-white"
                                type="number"
                                name="aku"
                                placeholder={`0`}
                              />
                              <label className="mt-1 me-2 text-success fw-bolder ms-1">
                                UKA:
                              </label>
                              <input
                                onChange={this.setInputSubQuest}
                                id="uka"
                                className="form-control form-control-sm   border-success bg-dark text-white "
                                type="number"
                                name="uka"
                                placeholder={`0`}
                              />
                              <label className="mt-1 me-2 text-success fw-bolder ms-1">
                                NFT:
                              </label>
                              <input
                                onChange={this.setInputSubQuest}
                                id="nft"
                                className="form-control form-control-sm   border-success bg-dark text-white "
                                type="number"
                                name="nft"
                                placeholder={`0`}
                              />
                              <label className="mt-1 me-2 text-success fw-bolder ms-1 fs-8">
                                score NEED:
                              </label>
                              <input
                                onChange={this.setInputSubQuest}
                                id="pre_score"
                                className="form-control form-control-sm   border-success bg-dark text-white me-5"
                                type="number"
                                name="pre_score"
                                placeholder={`0`}
                              />
                            </span>
                            <div className="d-flex justify-content-evenly mt-3">
                              <div className="form-check">
                                <input
                                  onChange={this.setInputSubQuest}
                                  className="form-check-input"
                                  type="radio"
                                  name="answertype"
                                  id="link"
                                  value="link"
                                />
                                <label className="form-check-label" for="link">
                                  Link
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  onChange={this.setInputSubQuest}
                                  className="form-check-input"
                                  type="radio"
                                  name="answertype"
                                  id="text"
                                  value="text"
                                />
                                <label className="form-check-label" for="text">
                                  Text
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  onChange={this.setInputSubQuest}
                                  className="form-check-input"
                                  type="radio"
                                  name="answertype"
                                  id="image"
                                  value="img"
                                />
                                <label className="form-check-label" for="image">
                                  Image
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  onChange={this.setInputSubQuest}
                                  className="form-check-input"
                                  type="radio"
                                  name="answertype"
                                  id="null"
                                  value="null"
                                />
                                <label className="form-check-label" for="null">
                                  Empty
                                </label>
                              </div>
                            </div>
                            {this.state.quest === "special" ? (
                              <>
                                <div className="d-flex justify-content-evenly mt-3">
                                  <div className="form-check">
                                    <input
                                      onChange={this.setInputSubQuest}
                                      className="form-check-input"
                                      type="radio"
                                      name="SAP"
                                      id="image"
                                      value={1}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="image"
                                    >
                                      Show After Pass
                                    </label>
                                  </div>

                                  <div className="form-check">
                                    <input
                                      onChange={this.setInputSubQuest}
                                      className="form-check-input"
                                      type="radio"
                                      name="SAP"
                                      id="null"
                                      value={0}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="null"
                                    >
                                      Don't Show After Pass
                                    </label>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            <span className="text-center w-100 mt-3">
                              <button
                                onClick={this.handleSubQuest}
                                className={
                                  this.state.quest === ""
                                    ? "btn btn-sm btn-dark ms-auto me-auto d-flex mt-3 "
                                    : "btn btn-sm btn-success ms-auto me-auto d-flex mt-3"
                                }
                              >
                                ADD{" "}
                                {this.state.quest === "special"
                                  ? "once"
                                  : this.state.quest}{" "}
                                Quest
                              </button>
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="border border-1 overflow-auto h-750 p-3">
                          <div className="row justify-content-evenly d-flex w-100 p-1 ">
                            {!this.state.daily &&
                            !this.state.weekly &&
                            !this.state.special ? (
                              <>
                                <div className="col-12 text-start text-success">
                                  #Daily
                                </div>
                                {this.props.dailyPending.map((item, index) => {
                                  return (
                                    <>
                                      <div className="col-12 border border-1 bg-dark my-1 ">
                                        <QuestPending
                                          wtoT={this.props.wtoT}
                                          refreshAll={this.props.refreshAll}
                                          index={index}
                                          type={"daily"}
                                          item={item}
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                                <div className="col-12 text-start text-danger">
                                  #Weekly
                                </div>
                                {this.props.weeklyPending.map((item, index) => {
                                  return (
                                    <>
                                      <div className="col-12 border border-1 bg-dark my-1 ">
                                        <QuestPending
                                          wtoT={this.props.wtoT}
                                          refreshAll={this.props.refreshAll}
                                          index={index}
                                          type={"weekly"}
                                          item={item}
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                                <div className="col-12 text-start text-warning">
                                  #Once
                                </div>
                                {this.props.specialPending.map(
                                  (item, index) => {
                                    return (
                                      <>
                                        <div className="col-12 border border-1 bg-dark my-1 ">
                                          <QuestPending
                                            wtoT={this.props.wtoT}
                                            refreshAll={this.props.refreshAll}
                                            index={index}
                                            type={"special"}
                                            item={item}
                                          />
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <>
                                {this.state.daily ? (
                                  <>
                                    <div className="col-12 text-start text-success">
                                      #Daily
                                    </div>
                                    {this.props.dailyPending.map(
                                      (item, index) => {
                                        return (
                                          <>
                                            <div className="col-12 border border-1 bg-dark my-1 ">
                                              <QuestPending
                                                wtoT={this.props.wtoT}
                                                refreshAll={
                                                  this.props.refreshAll
                                                }
                                                index={index}
                                                type={"daily"}
                                                item={item}
                                              />
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                                {this.state.weekly ? (
                                  <>
                                    {" "}
                                    <div className="col-12 text-start text-danger">
                                      #Weekly
                                    </div>
                                    {this.props.weeklyPending.map(
                                      (item, index) => {
                                        return (
                                          <>
                                            <div className="col-12 border border-1 bg-dark my-1 ">
                                              <QuestPending
                                                wtoT={this.props.wtoT}
                                                refreshAll={
                                                  this.props.refreshAll
                                                }
                                                index={index}
                                                type={"weekly"}
                                                item={item}
                                              />
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                                {this.state.special ? (
                                  <>
                                    <div className="col-12 text-start text-warning">
                                      #Once
                                    </div>
                                    {this.props.specialPending.map(
                                      (item, index) => {
                                        return (
                                          <>
                                            <div className="col-12 border border-1 bg-dark my-1 ">
                                              <QuestPending
                                                wtoT={this.props.wtoT}
                                                refreshAll={
                                                  this.props.refreshAll
                                                }
                                                index={index}
                                                type={"special"}
                                                item={item}
                                              />
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-12 col-lg-7 p-2">
                    <div className="border border-1 overflow-auto h-750 p-1">
                      <div className="row justify-content-evenly d-flex w-100 ">
                        {!this.state.daily &&
                        !this.state.weekly &&
                        !this.state.special ? (
                          <>
                            <div className="col-12 text-start text-success">
                              #Daily
                            </div>
                            {this.props.dailyQuests.map((item, index) => {
                              return (
                                <>
                                  <QuestItem
                                    refreshAll={this.props.refreshAll}
                                    index={index}
                                    type={"daily"}
                                    item={item}
                                  />
                                </>
                              );
                            })}
                            <div className="col-12 text-start text-danger">
                              #Weekly
                            </div>
                            {this.props.weeklyQuests.map((item, index) => {
                              return (
                                <>
                                  <QuestItem
                                    refreshAll={this.props.refreshAll}
                                    index={index}
                                    type={"weekly"}
                                    item={item}
                                  />
                                </>
                              );
                            })}
                            <div className="col-12 text-start text-warning">
                              #Once
                            </div>
                            {this.props.specialQuests.map((item, index) => {
                              return (
                                <>
                                  <QuestItem
                                    refreshAll={this.props.refreshAll}
                                    index={index}
                                    type={"special"}
                                    item={item}
                                  />
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {this.state.daily ? (
                              <>
                                <div className="col-12 text-start text-success">
                                  #Daily
                                </div>
                                {this.props.dailyQuests.map((item, index) => {
                                  return (
                                    <>
                                      <QuestItem
                                        refreshAll={this.props.refreshAll}
                                        index={index}
                                        type={"daily"}
                                        item={item}
                                      />
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                            {this.state.weekly ? (
                              <>
                                <div className="col-12 text-start text-danger">
                                  #Weekly
                                </div>
                                {this.props.weeklyQuests.map((item, index) => {
                                  return (
                                    <>
                                      <QuestItem
                                        refreshAll={this.props.refreshAll}
                                        index={index}
                                        type={"weekly"}
                                        item={item}
                                      />
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                            {this.state.special ? (
                              <>
                                <div className="col-12 text-start text-warning">
                                  #Once
                                </div>
                                {this.props.specialQuests.map((item, index) => {
                                  return (
                                    <>
                                      <QuestItem
                                        refreshAll={this.props.refreshAll}
                                        index={index}
                                        type={"special"}
                                        item={item}
                                      />
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12  p-2">
                    <div className="border border-1 overflow-auto h-300n p-1">
                      f
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Quests;
