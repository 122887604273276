import React, { Component } from "react";
import InviteItem from "./InviteItem";

class Invite extends Component {
  constructor(props) {
    super(props);
  }
  state = { createKey: { score: 0, count: 20 }, search: "" };
  setInputSearch = (e) => {
    const { name, value } = e.target;

    this.setState(() => ({
      search: value,
    }));
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      createKey: {
        ...prevState.createKey,
        [name]: value,
      },
    }));
  };
  checkInviteKeyExist = async (k) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/checkInviteKeyExist",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          key: k,
        }),
      }
    ).then((res) =>
      res.json().then((data) => {
        return data;
      })
    );
    return get;
  };
  insertInviteKey = async (k, score) => {
    const get = await fetch("https://managerapi.the369.xyz/insertInviteKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        key: k,
        score: score,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  generateKey = () => {
    let x = Math.floor(Math.random() * 100000000000 + 369);
    return x;
  };
  handleCreateKey = async () => {
    document.getElementById("key-list").innerHTML = "";
    const CK = this.state.createKey;

    var i = 0;
    while (i < CK.count) {
      var temp_key = this.generateKey();

      const res = await this.checkInviteKeyExist(temp_key);
      if (res.length === 0) {
        const add = await this.insertInviteKey(temp_key, parseInt(CK.score));
        if (add) {
          i++;
          document.getElementById("key-list").innerHTML += ` ${temp_key} `;
        } else {
        }
      } else {
      }
    }
    document.getElementById("refresh").click();
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row justify-content-evenly align-self-center g-1 w-100">
              <div className="col-12  p-2">
                <div className="border border-1 p-1 overflow-auto h-300n text-center row">
                  <div className="col-12 col-lg-5 mt-3">
                    <div className="border border-1 p-3 overflow-auto  text-center d-flex flex-column">
                      <span className=" mt-3 fw-bolder fs-4">Create key</span>

                      <span className="d-flex mt-3">
                        <label className="mt-1 me-2 text-danger fw-bolder ms-1">
                          Start SCORE:
                        </label>
                        <input
                          onChange={this.setInput}
                          id="score"
                          className="form-control form-control-sm   border-success bg-dark text-white"
                          type="number"
                          name="score"
                          placeholder={`0`}
                        />
                        <label className="mt-3 me-2 text-success fw-bolder ms-1">
                          COUNT:
                        </label>
                        <input
                          onChange={this.setInput}
                          id="count"
                          className="form-control form-control-sm   border-success bg-dark text-white"
                          type="number"
                          name="count"
                          placeholder={`20`}
                        />
                      </span>
                      <span className="d-flex flex-column mb-auto">
                        <span
                          id="sub-key-err"
                          className=" text-danger mt-3"
                        ></span>
                        <span
                          id="sub-key-suc"
                          className=" text-success mt-3"
                        ></span>
                        <span className=" text-danger mt-2">
                          <button
                            onClick={this.handleCreateKey}
                            className="btn btn-sm btn-success"
                          >
                            Create
                          </button>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div id="key-list" className="col-12 col-lg-7"></div>
                </div>
              </div>
              <div className="col-12  p-2">
                <div className="border border-1 p-1 overflow-auto h-400n text-center d-flex flex-column">
                  <span className=" mt-3 fw-bolder fs-4">see Invite key</span>
                  <div className="row justify-content-evenly w-100 p-2">
                    <div className="col-12">
                      <input
                        onChange={this.setInputSearch}
                        id="search"
                        className="form-control form-control-sm m-2  border-success bg-dark text-white"
                        type="text"
                        name="search"
                        placeholder={`Search By key`}
                      />
                    </div>
                    {this.props.inviteKeys.map((item) => {
                      if (this.state.search === "") {
                        return (
                          <>
                            <InviteItem
                              wtoT={this.props.wtoT}
                              refreshAll={this.props.refreshAll}
                              item={item}
                            />
                          </>
                        );
                      } else {
                        if (
                          item.code
                            .toLowerCase()
                            .search(`${this.state.search.toLowerCase()}`) !== -1
                        ) {
                          return (
                            <>
                              <InviteItem
                                wtoT={this.props.wtoT}
                                refreshAll={this.props.refreshAll}
                                item={item}
                              />
                            </>
                          );
                        } else {
                          return <></>;
                        }
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Invite;
