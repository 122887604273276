import React, { Component } from "react";
import ConvertLog from "./ConvertLog";
import Lcard from "./Lcard";
import UserLog from "./UserLog";
class Users extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    search: "",
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState(() => ({
      search: value,
    }));
  };
  walletGenerator = (w) => {
    let newWallet = w.split("");

    let length = newWallet.length;

    let temp =
      newWallet[0] +
      newWallet[1] +
      newWallet[2] +
      newWallet[3] +
      newWallet[4] +
      "..." +
      newWallet[length - 4] +
      newWallet[length - 3] +
      newWallet[length - 2] +
      newWallet[length - 1];

    return temp;
  };
  target = () => {
    var target = document.getElementById("target");
    target.classList.remove("target-done");
    target.classList.add("target");
    setTimeout(() => {
      target.classList.add("target-done");
      target.classList.remove("target");
    }, 1500);
  };
  render() {
    return (
      <>
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row justify-content-evenly align-self-center g-1 w-100">
              <div className="col-12">
                <div className="row justify-content-evenly align-self-center g-1 w-100">
                  {this.props.tReqNotif.count !== 0 ? (
                    <>
                      <div className="col-12 text-center text-danger">
                        {`There is ${this.props.tReqNotif.count} twitter change request !`}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="col-12 col-md-6">
                    <input
                      onChange={this.setInput}
                      id="search"
                      className="form-control form-control-sm m-2  border-success bg-dark text-white"
                      type="text"
                      name="search"
                      placeholder={`Search By Twitter ID`}
                    />
                  </div>
                  <div className="col-12 text-center mb-2">
                    {" "}
                    <UserLog userLog={this.props.userLog} />
                    <ConvertLog convertLog={this.props.convertLog} />
                  </div>
                </div>
              </div>
              {this.props.usersList.map((item) => {
                if (this.state.search === "") {
                  return (
                    <>
                      <Lcard
                        holders={this.props.holders}
                        refreshAll={this.props.refreshAll}
                        notif={this.props.notif}
                        tReq={this.props.tReq}
                        item={item}
                        validKey={this.props.validKey}
                        wrongKey={this.props.wrongKey}
                        SentMessages={this.props.SentMessages}
                        tReqNotif={this.props.tReqNotif}
                        inboxMessage={this.props.inboxMessage}
                        wtoT={this.props.wtoT}
                        dailyNotif={this.props.dailyNotif}
                        weeklyNotif={this.props.weeklyNotif}
                        specialNotif={this.props.specialNotif}
                      />
                    </>
                  );
                } else {
                  if (
                    item.Twitter_Id.toLowerCase().search(
                      `${this.state.search.toLowerCase()}`
                    ) !== -1
                  ) {
                    return (
                      <>
                        <Lcard
                          holders={this.props.holders}
                          refreshAll={this.props.refreshAll}
                          notif={this.props.notif}
                          tReq={this.props.tReq}
                          item={item}
                          validKey={this.props.validKey}
                          wrongKey={this.props.wrongKey}
                          SentMessages={this.props.SentMessages}
                          tReqNotif={this.props.tReqNotif}
                          inboxMessage={this.props.inboxMessage}
                          wtoT={this.props.wtoT}
                          dailyNotif={this.props.dailyNotif}
                          weeklyNotif={this.props.weeklyNotif}
                          specialNotif={this.props.specialNotif}
                        />
                      </>
                    );
                  } else {
                    return <></>;
                  }
                }
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Users;
