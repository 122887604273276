import React, { Component } from "react";
import SCABI from "../smartContractABI/ABI.json";
import { ethers } from "ethers";
const SCAddress = "0x71AA3598C7577943FE17B756874271A77c9Db34B";
class UpdateHolders extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  updateHolder = async (w, i) => {
    const get = await fetch("https://managerapi.the369.xyz/updateHolder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        tokenId: i,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      return false;
    }
  };
  UpdateNFTcount = async (w, c) => {
    const get = await fetch("https://managerapi.the369.xyz/UpdateNFTcount", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        NFTcount: c,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
    }
    if (get.status === "failed") {
    }
  };
  updateHolders = async () => {
    const btn = document.getElementById("nft-update-btn");
    btn.innerHTML = "UPDATING...";
    btn.classList.add("disable-link");
    const contract = new ethers.Contract(SCAddress, SCABI, this.props.library);
    try {
      const totalSupply = await contract.totalSupply();
      const TS = parseInt(String(totalSupply), 10);

      var tes = {};
      for (var i = 1; i <= TS; i++) {
        const tokenOwner = await contract.ownerOf(i);
        // console.log("owner Of ", i, " is: ", String(tokenOwner));
        tes[String(tokenOwner)]
          ? tes[String(tokenOwner)]++
          : (tes[String(tokenOwner)] = 1);
        const ures = await this.updateHolder(String(tokenOwner), i);
        if (ures) {
          document.getElementById("last-check-nft").innerHTML = i;
        } else {
          alert(`Something wrong on: ${i}`);
        }
      }
      console.table(tes);
      this.props.usersList.map((item) => {
        if (tes[item.wallet_address] !== undefined) {
          // console.log(item.wallet_address," --------> has : ",tes[item.wallet_address]);
          this.UpdateNFTcount(item.wallet_address, tes[item.wallet_address]);
        } else {
          this.UpdateNFTcount(item.wallet_address, 0);
        }
      });
    } catch (err) {
      console.log("Error : ", err);
    }
    btn.innerHTML = "UPDATE HOLDERS";
    btn.classList.remove("disable-link");
    document.getElementById("refresh").click();
    document.getElementById("last-check-nft").innerHTML =
      "Updated successfully";
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <button
          id="nft-update-btn"
          onClick={this.updateHolders}
          className="btn btn-sm bg-warning w-fit ms-auto me-auto"
        >
          UPDATE HOLDERS
        </button>
        <span id="last-check-nft" className="fs-4 fw-bold mt-2"></span>
      </>
    );
  }
}

export default UpdateHolders;
