import React, { Component } from "react";
import Linkify from "react-linkify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class InboxMessageItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    editModal: false,
    ReplyMessage: {
      subject: `RE: ${this.props.item.subject}`,
      message: "",
      sender: this.props.item.receiver,
      link_title: "",
    },
  };
  setInputReplyMessage = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      ReplyMessage: {
        ...prevState.ReplyMessage,
        [name]: value,
      },
    }));
  };
  sendMessage = async (s, m, sender, r, l) => {
    const get = await fetch("https://managerapi.the369.xyz/sendMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        subject: s,
        message: m,
        sender: sender,
        receiver: r,
        link_title: l,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();

      this.handleClose();
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  handleReplyMessage = () => {
    const RM = this.state.ReplyMessage;

    this.sendMessage(
      RM.subject,
      RM.message,
      RM.sender,
      this.props.item.sender,
      RM.link_title
    );
  };
  UnreadSentMessage = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/UnreadSentMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        SM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  ReadSentMessage = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/ReadSentMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        SM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  SeenSentMessage = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/SeenSentMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        SM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  handleClose = () => {
    this.setState({ editModal: false });
  };
  handleOpen = () => {
    this.setState({ editModal: true });
  };

  ReplyMessage = async (id, s, m, sender, l) => {
    const get = await fetch("https://managerapi.the369.xyz/ReplyMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        SM_ID: id,
        subject: s,
        message: m,
        sender: sender,
        link_title: l,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      //   alert("successfully Changed.");
      this.handleClose();
    }
    if (get.status === "failed") {
    }
  };
  RestoreSentMessage = async (id) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/RestoreSentMessage",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          SM_ID: id,
        }),
      }
    ).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  DeleteSentMessage = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/DeleteSentMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        SM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };

  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <span
          className={
            this.props.item.deleted === 1
              ? "col-2 right-border text-start text-danger px-2"
              : "col-2 right-border text-start px-2"
          }
        >
          {this.props.item.subject}
        </span>
        <span
          className={
            this.props.item.deleted === 1
              ? "col-5 right-border text-start text-danger px-2 new-line"
              : "col-5 right-border text-start px-2 new-line"
          }
        >
          <Linkify
            componentDecorator={(decoratedHref) => (
              <a
                target="blank"
                href={decoratedHref}
                className=" not-underline msg-link bg-info mt-1"
              >
                {this.props.item.link_title}
              </a>
            )}
          >
            {this.props.item.message}
          </Linkify>
        </span>
        <span className="col-1 right-border text-start px-2">
          {this.props.item.receiver}
        </span>
        <span className="col-1 right-border text-start px-2">
          <a
            className={
              this.props.item.status === "unread"
                ? "not-underline text-danger"
                : "not-underline text-white"
            }
            href={`https://twitter.com/${
              this.props.wtoT[this.props.item.sender]
            }`}
            target="_blank"
          >
            <i className="bi bi-twitter fs-6 text-info"></i>
            {this.props.wtoT[this.props.item.sender]}
          </a>
        </span>
        <span className="col-1 right-border text-center px-2">
          {this.props.item.status === "unread" ? (
            <>
              <i
                title="Unread"
                className="bi bi-check2 text-danger fs-5  right-border me-3 pe-2"
              ></i>
            </>
          ) : this.props.item.status === "read" ? (
            <>
              <i
                title="Read"
                className="bi bi-check2-all text-success fs-5  right-border me-3 pe-2 "
              ></i>
            </>
          ) : (
            <>
              <i
                title="Secret Read"
                className="bi bi-asterisk text-success fs-6  right-border me-3 pe-2"
              ></i>
            </>
          )}
          {this.props.item.status === "unread" ? (
            <>
              <i
                onClick={() => {
                  this.ReadSentMessage(this.props.item.SM_ID);
                }}
                title="Read"
                className="bi bi-check2-all  fs-5 c-pointer"
              ></i>
              <i
                onClick={() => {
                  this.SeenSentMessage(this.props.item.SM_ID);
                }}
                title="Secret Read"
                className="bi bi-asterisk  fs-6 c-pointer"
              ></i>
            </>
          ) : (
            <></>
          )}
          {this.props.item.status === "read" ? (
            <>
              <i
                onClick={() => {
                  this.UnreadSentMessage(this.props.item.SM_ID);
                }}
                title="Unread"
                className="bi bi-check2  fs-5 c-pointer "
              ></i>

              <i
                onClick={() => {
                  this.SeenSentMessage(this.props.item.SM_ID);
                }}
                title="Secret Read"
                className="bi bi-asterisk  fs-6 c-pointer"
              ></i>
            </>
          ) : (
            <></>
          )}
          {this.props.item.status === "seen" ? (
            <>
              <i
                onClick={() => {
                  this.UnreadSentMessage(this.props.item.SM_ID);
                }}
                title="Unread"
                className="bi bi-check2  fs-5 c-pointer "
              ></i>
              <i
                onClick={() => {
                  this.ReadSentMessage(this.props.item.SM_ID);
                }}
                title="Read"
                className="bi bi-check2-all  fs-5 c-pointer"
              ></i>
            </>
          ) : (
            <></>
          )}
        </span>
        <span className="col-1 right-border  text-start px-2 text-muted fs-8">
          {this.timeGenarator(this.props.item.Time)}
        </span>
        <span className="col-1   text-start px-2 text-center fs-8">
          {this.props.item.deleted === 1 ? (
            <>
              <i
                onClick={() => {
                  this.RestoreSentMessage(this.props.item.SM_ID);
                }}
                title="Restore"
                className="bi bi-arrow-clockwise fs-6 text-success c-pointer me-2 "
              ></i>
            </>
          ) : (
            <>
              <i
                onClick={() => {
                  this.DeleteSentMessage(this.props.item.SM_ID);
                }}
                title="Delete"
                className="bi bi-trash3 fs-6 text-danger c-pointer me-2"
              ></i>
            </>
          )}

          <i
            onClick={this.handleOpen}
            title="Reply"
            className="bi bi-reply-fill fs-6 text-white c-pointer "
          ></i>
        </span>

        <Modal
          show={this.state.editModal}
          onHide={() => {
            this.handleClose();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                edit message : {this.props.item.subject}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex flex-column p-3">
              <input
                id="sender"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Sender :${this.props.item.receiver}`}
                onChange={this.setInputReplyMessage}
                name="sender"
              />
              <input
                id="subject"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Subject : RE: ${this.props.item.subject}`}
                onChange={this.setInputReplyMessage}
                name="subject"
              />
              <textarea
                type="text"
                className=" form-control form-control-xl bg-dark text-white border-success form-signup ms-2"
                id="message"
                onChange={this.setInputReplyMessage}
                name="message"
                placeholder={`Message...`}
                required
              ></textarea>
              <input
                id="link_title"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Link Titele`}
                onChange={this.setInputReplyMessage}
                name="link_title"
              />
              <button
                id="btn"
                onClick={this.handleReplyMessage}
                className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 mt-auto mb-5"
              >
                SEND
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default InboxMessageItem;
