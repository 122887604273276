import React, { Component } from "react";
import NFTItem from "./NFTItem";
import UpdateHolders from "./UpdateHolders";

class Holders extends Component {
  constructor(props) {
    super(props);
  }
  state = { searchByNum: "", searchByTwitter: "", justMembers: false };
  justMembersOn = () => {
    if (this.state.searchByTwitter !== "") {
      this.setState({ searchByTwitter: "" });
    }
    if (this.state.searchByNum !== "") {
      this.setState({ searchByNum: "" });
    }
    this.setState({ justMembers: true });
  };
  justMembersOff = () => {
    if (this.state.searchByTwitter !== "") {
      this.setState({ searchByTwitter: "" });
    }
    if (this.state.searchByNum !== "") {
      this.setState({ searchByNum: "" });
    }
    this.setState({ justMembers: false });
  };
  setInput = (e) => {
    if (this.state.searchByTwitter !== "") {
      this.setState({ searchByTwitter: "" });
    }
    const { name, value } = e.target;

    this.setState(() => ({
      searchByNum: value,
    }));
  };
  setInputTwitterSearch = (e) => {
    if (this.state.searchByNum !== "") {
      this.setState({ searchByNum: "" });
    }
    const { name, value } = e.target;

    this.setState(() => ({
      searchByTwitter: value,
    }));
  };
  counter = () => {
    var i = 0;

    this.props.holders.map((item) => {
      if (!this.state.justMembers) {
        if (
          this.state.searchByNum === "" &&
          this.state.searchByTwitter === ""
        ) {
          i++;
        } else {
          if (this.state.searchByNum !== "") {
            if (item.token_id === parseInt(this.state.searchByNum)) {
              i++;
            } else {
              return null;
            }
          } else {
            if (this.props.wtoT[item.wallet_address] !== undefined) {
              if (
                this.props.wtoT[item.wallet_address]
                  .toLowerCase()
                  .search(`${this.state.searchByTwitter.toLowerCase()}`) !== -1
              ) {
                i++;
              } else {
                return null;
              }
            } else {
              return null;
            }
          }
        }
      } else {
        if (this.props.wtoT[item.wallet_address] !== undefined) {
          if (
            this.state.searchByNum === "" &&
            this.state.searchByTwitter === ""
          ) {
            i++;
          } else {
            if (this.state.searchByNum !== "") {
              if (item.token_id === parseInt(this.state.searchByNum)) {
                i++;
              } else {
                return null;
              }
            } else {
              if (this.props.wtoT[item.wallet_address] !== undefined) {
                if (
                  this.props.wtoT[item.wallet_address]
                    .toLowerCase()
                    .search(`${this.state.searchByTwitter.toLowerCase()}`) !==
                  -1
                ) {
                  i++;
                } else {
                  return null;
                }
              } else {
                return null;
              }
            }
          }
        }
      }
    });

    return i;
  };
  render() {
    if (
      this.state.searchByNum === "" &&
      document.getElementById("searchByNum") !== null
    ) {
      document.getElementById("searchByNum").value = "";
    }
    if (
      this.state.searchByTwitter === "" &&
      document.getElementById("searchByTwitter") !== null
    ) {
      document.getElementById("searchByTwitter").value = "";
    }

    return (
      <>
        {" "}
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row justify-content-evenly  g-1 w-100">
              <div className="col-12 text-center mt-3 d-flex flex-column">
                <UpdateHolders
                  usersList={this.props.usersList}
                  library={this.props.library}
                  refreshAll={this.props.refreshAll}
                />
              </div>
              <div className="col-12 border border-1 overflow-auto p-2 h-600 ">
                <div
                  id="test"
                  className="row justify-content-evenly align-self-center g-1 w-100"
                >
                  <div className="col-12 d-flex flex-column flex-md-row">
                    <input
                      onChange={this.setInput}
                      id="searchByNum"
                      className="form-control form-control-sm m-2  border-success bg-dark text-white"
                      type="text"
                      name="searchByNum"
                      placeholder={`Search By NFT Number`}
                    />
                    <input
                      onChange={this.setInputTwitterSearch}
                      id="searchByTwitter"
                      className="form-control form-control-sm m-2  border-success bg-dark text-white"
                      type="text"
                      name="searchByTwitter"
                      placeholder={`Search By Twitter`}
                    />
                    {this.state.justMembers ? (
                      <>
                        <button
                          onClick={this.justMembersOff}
                          className="btn btn-sm btn-danger"
                        >
                          ALL
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={this.justMembersOn}
                          className="btn btn-sm btn-success"
                        >
                          Members
                        </button>
                      </>
                    )}{" "}
                  </div>
                  <div className="col-12 text-center mb-2 mt-2">
                    <span className="p-2 bg-dark text-white fw-bolder rounded rounded-pill">
                      {this.counter()} FOUNDED
                    </span>
                  </div>
                  {this.props.holders.map((item) => {
                    if (!this.state.justMembers) {
                      if (
                        this.state.searchByNum === "" &&
                        this.state.searchByTwitter === ""
                      ) {
                        return (
                          <>
                            <NFTItem wtoT={this.props.wtoT} item={item} />
                          </>
                        );
                      } else {
                        if (this.state.searchByNum !== "") {
                          if (
                            item.token_id === parseInt(this.state.searchByNum)
                          ) {
                            return (
                              <>
                                <NFTItem wtoT={this.props.wtoT} item={item} />
                              </>
                            );
                          } else {
                            return null;
                          }
                        } else {
                          if (
                            this.props.wtoT[item.wallet_address] !== undefined
                          ) {
                            if (
                              this.props.wtoT[item.wallet_address]
                                .toLowerCase()
                                .search(
                                  `${this.state.searchByTwitter.toLowerCase()}`
                                ) !== -1
                            ) {
                              return (
                                <>
                                  <NFTItem wtoT={this.props.wtoT} item={item} />
                                </>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }
                        }
                      }
                    } else {
                      if (this.props.wtoT[item.wallet_address] !== undefined) {
                        if (
                          this.state.searchByNum === "" &&
                          this.state.searchByTwitter === ""
                        ) {
                          return (
                            <>
                              <NFTItem wtoT={this.props.wtoT} item={item} />
                            </>
                          );
                        } else {
                          if (this.state.searchByNum !== "") {
                            if (
                              item.token_id === parseInt(this.state.searchByNum)
                            ) {
                              return (
                                <>
                                  <NFTItem wtoT={this.props.wtoT} item={item} />
                                </>
                              );
                            } else {
                              return null;
                            }
                          } else {
                            if (
                              this.props.wtoT[item.wallet_address] !== undefined
                            ) {
                              if (
                                this.props.wtoT[item.wallet_address]
                                  .toLowerCase()
                                  .search(
                                    `${this.state.searchByTwitter.toLowerCase()}`
                                  ) !== -1
                              ) {
                                return (
                                  <>
                                    <NFTItem
                                      wtoT={this.props.wtoT}
                                      item={item}
                                    />
                                  </>
                                );
                              } else {
                                return null;
                              }
                            } else {
                              return null;
                            }
                          }
                        }
                      }
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Holders;
