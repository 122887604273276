import React, { useState, useEffect } from "react";
import { flushSync } from "react-dom";
// import {connect} from "./functions/walletconnect";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "../providers/providerOptions";
import SCABI from "../smartContractABI/ABI.json";
import backdround from "../images/background/back.gif";
import Menu from "./Menu";
import Footer from "./Footer";
import Home from "./Home";
import Quests from "./Quests";
import Messages from "./Messages";
// import Loading from "./Loading";
import Keys from "./Keys";
import Invite from "./Invite";
import Users from "./Users";
import Holders from "./Holders";
const SCAddress = "0x71AA3598C7577943FE17B756874271A77c9Db34B";
const baseTokenURI =
  "https://ipfs.io/ipfs/bafybeigwzjagvx3ic5a4jzlzdm5mifbm455umi2rt2wo3toovecbxexiwm/";
const web3Modal = new Web3Modal({
  cacheProvider: false, // optional
  providerOptions, // required
  theme: "dark",
  disableInjectedProvider: false,
});

const Main = () => {
  const [accounts, setAccounts] = useState([]);
  const [user, setUser] = useState([]);
  const [users, setUsers] = useState();
  const [keys, setKeys] = useState();
  const [wtoT, setWtoT] = useState();
  const [unreadCount, setUnreadCount] = useState();
  const [broadcasts, setBroadcasts] = useState();
  const [notif, setNotif] = useState();
  const [userLog, setUserLog] = useState();
  const [convertLog, setConvertLog] = useState();
  const [dailyPending, setDailyPending] = useState({});
  const [weeklyPending, setWeeklyPending] = useState({});
  const [specialPending, setSpecialPending] = useState({});
  const [dailyNotif, setDailyNotif] = useState({});
  const [weeklyNotif, setWeeklyNotif] = useState({});
  const [specialNotif, setSpecialNotif] = useState({});
  const [inboxMessage, setInboxMessage] = useState();
  const [holders, setHolders] = useState();
  const [tReq, setTReq] = useState();
  const [tReqNotif, setTReqNotif] = useState({ count: 0 });
  const [sentMessages, setSentMessages] = useState();
  const [inviteKeys, setInviteKeys] = useState();
  const [broadcastStatus, setBroadcastStatus] = useState();
  const [validKey, setValidKey] = useState();
  const [wrongKey, setWrongKey] = useState();
  const [dailyQuests, setDailyQuests] = useState();
  const [weeklyQuests, setWeeklyQuests] = useState();
  const [specialQuests, setSpecialQuests] = useState();
  const [isMember, setIsMember] = useState(false);
  const [dataIsGet, setDataIsGet] = useState(false);
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [homePage, setHomePage] = useState(true);
  const [keysPage, setKeysPage] = useState(false);
  const [questsPage, setQuestsPage] = useState(false);
  const [messagesPage, setMessagesPage] = useState(false);
  const [inviteKeysPage, setInviteKeysPage] = useState(false);
  const [usersPage, setUsersPage] = useState(false);
  const [holdersPage, setHoldersPage] = useState(false);
  const isConnected = Boolean(accounts[0]);
  const refreshPage = () => {
    setHomePage(false);
    setKeysPage(false);
    setQuestsPage(false);
    setMessagesPage(false);
    setInviteKeysPage(false);
    setUsersPage(false);
    setHoldersPage(false);
  };
  const handleHomePage = () => {
    refreshPage();
    setHomePage(true);
  };
  const handleKeysPage = () => {
    refreshPage();
    setKeysPage(!keysPage);
  };
  const handleQuestsPage = () => {
    refreshPage();
    setQuestsPage(!questsPage);
  };

  const handleMessagesPage = () => {
    refreshPage();
    setMessagesPage(!messagesPage);
  };
  const handleInviteKeysPage = () => {
    refreshPage();
    setInviteKeysPage(!inviteKeysPage);
  };
  const handleUsersPage = () => {
    refreshPage();
    setUsersPage(!usersPage);
  };
  const handleHoldersPage = () => {
    refreshPage();
    setHoldersPage(!holdersPage);
  };
  const refreshAll = () => {
    GetUsers();
    getDailyQuestsNotif();
    getWeeklyQuestsNotif();
    getSpecialQuestsNotif();
    getDailyQuests();
    getWeeklyQuests();
    getSpecialQuests();
    GetBroadcast();
    GetHolders();
    getDailyPending();
    getWeeklyPending();
    getSpecialPending();
    GetInviteKeys();
    GetKeys();
    GetNotif();
    getUserLog();
    getConvertLog();
    GetInboxMessage();

    getBroadcastStatus();

    GetTReq();
    GetValidKey();
    GetWrongKey();
    getSentMessages();
  };
  const GetUsers = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getAllUsers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        var fes = {};
        data.forEach(function (item) {
          fes[item.wallet_address] = item.Twitter_Id;
        });

        setWtoT(fes);
        setUsers(data);
      })
    );
    return get;
  };
  const getDailyQuests = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getDailyQuests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setDailyQuests(data);
      })
    );
    return get;
  };
  const getWeeklyQuests = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getWeeklyQuests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setWeeklyQuests(data);
      })
    );
    return get;
  };
  const getSpecialQuests = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getSpecialQuests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setSpecialQuests(data);
      })
    );
    return get;
  };
  const GetInviteKeys = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getInviteKeys", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setInviteKeys(data);
      })
    );
    return get;
  };
  const GetWrongKey = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getWrongKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setWrongKey(data);
      })
    );
    return get;
  };
  const GetValidKey = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getValidKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setValidKey(data);
      })
    );
    return get;
  };
  const GetBroadcast = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getBroadcast", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setBroadcasts(data);
      })
    );
    return get;
  };
  const GetKeys = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getKeys", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setKeys(data);
      })
    );
    return get;
  };
  const GetHolders = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getHolders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setHolders(data);
      })
    );
    return get;
  };
  const getBroadcastStatus = async () => {
    const get = await fetch(
      "https://managerapi.the369.xyz/getBroadcastStatus",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      }
    ).then((res) =>
      res.json().then((data) => {
        var bes = {};
        data.forEach(function (item) {
          bes[item.msg_id]
            ? bes[item.msg_id].push(item.user)
            : (bes[item.msg_id] = [`${item.user}`]);
        });

        setBroadcastStatus(bes);
      })
    );
    return get;
  };
  const GetInboxMessage = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getInboxMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        var ces = 0;
        data.forEach(function (item) {
          if (item.status === "unread") {
            ces++;
          }
        });
        setUnreadCount(ces);
        setInboxMessage(data);
      })
    );
    return get;
  };
  const getDailyPending = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getDailyPending", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setDailyPending(data);
      })
    );
    return get;
  };
  const getWeeklyPending = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getWeeklyPending", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setWeeklyPending(data);
      })
    );
    return get;
  };
  const getSpecialPending = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getSpecialPending", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setSpecialPending(data);
      })
    );
    return get;
  };
  const getDailyQuestsNotif = async () => {
    const get = await fetch(
      "https://managerapi.the369.xyz/getDailyQuestsNotif",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      }
    ).then((res) =>
      res.json().then((data) => {
        setDailyNotif(data);
      })
    );
    return get;
  };
  const getWeeklyQuestsNotif = async () => {
    const get = await fetch(
      "https://managerapi.the369.xyz/getWeeklyQuestsNotif",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      }
    ).then((res) =>
      res.json().then((data) => {
        setWeeklyNotif(data);
      })
    );
    return get;
  };
  const getSpecialQuestsNotif = async () => {
    const get = await fetch(
      "https://managerapi.the369.xyz/getSpecialQuestsNotif",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({}),
      }
    ).then((res) =>
      res.json().then((data) => {
        setSpecialNotif(data);
      })
    );
    return get;
  };
  const getUserLog = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getUserLog", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setUserLog(data);
      })
    );
    return get;
  };
  const getConvertLog = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getConvertLog", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setConvertLog(data);
      })
    );
    return get;
  };
  const GetNotif = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setNotif(data);
      })
    );
    return get;
  };
  const getSentMessages = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getSentMessages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        setSentMessages(data);
      })
    );
    return get;
  };
  const GetTReq = async () => {
    const get = await fetch("https://managerapi.the369.xyz/getTReq", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({}),
    }).then((res) =>
      res.json().then((data) => {
        var tes = { count: 0 };

        data.forEach(function (item) {
          if (item.status === "pending") {
            tes[item.user] ? tes[item.user]++ : (tes[item.user] = 1);
            tes.count++;
          }
        });

        setTReq(data);
        setTReqNotif(tes);
      })
    );
    return get;
  };
  const GetFromDB = async (w) => {
    const get = await fetch("https://managerapi.the369.xyz/checkAdminExist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
      }),
    }).then((res) => res.json());
    return get;
  };
  const refreshInterval = () => {
    setInterval(() => {
      refreshAll();
    }, 10000);
  };
  const handleconnect = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const wallets = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (wallets) setAccounts(wallets);
      if (network.chainId !== 1) {
        alert(
          "Your network is not on Ethereum Mainnet, Change your network and connect again. "
        );
        disconnect();
      } else {
        GetFromDB(wallets[0]).then((res) => {
          JSON.stringify(res);
          if (res[0]) {
            setIsMember(true);
            refreshInterval();
            setUser(res[0]);

            setDataIsGet(true);
            GetUsers();
            getDailyQuestsNotif();
            getWeeklyQuestsNotif();
            getSpecialQuestsNotif();
            getDailyQuests();
            getWeeklyQuests();
            getSpecialQuests();
            GetBroadcast();
            getDailyPending();
            getWeeklyPending();
            getSpecialPending();
            GetHolders();
            GetInviteKeys();
            getBroadcastStatus();
            GetKeys();
            GetNotif();
            getUserLog();
            getConvertLog();
            GetInboxMessage();
            GetTReq();
            GetValidKey();
            GetWrongKey();
            getSentMessages();
          } else {
            setUser([]);
            setIsMember(false);
            setDataIsGet(true);
          }
        });
        // GetUserTokens(wallets[0], library);
      }
      setChainId(network.chainId);
    } catch (error) {
      setError(error);
    }
  };
  const refreshState = () => {
    setAccounts([]);
  };

  const disconnect = () => {
    web3Modal.clearCachedProvider();
    setAccounts([]);
    setIsMember(false);
    if (!homePage) {
      handleHomePage();
    }
  };

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (wallets) => {
        handleHomePage();
        if (wallets) {
          setAccounts(wallets);
          GetFromDB(wallets[0]).then((res) => {
            JSON.stringify(res);
            if (res[0]) {
              setUser(res[0]);
              setIsMember(true);
              refreshInterval();
              setUser(res[0]);

              setDataIsGet(true);
              GetUsers();
              getDailyQuestsNotif();
              getWeeklyQuestsNotif();
              getSpecialQuestsNotif();
              getDailyQuests();
              getWeeklyQuests();
              getSpecialQuests();
              GetBroadcast();
              getDailyPending();
              getWeeklyPending();
              getSpecialPending();
              GetHolders();
              GetInviteKeys();
              getBroadcastStatus();
              GetKeys();
              GetNotif();
              getUserLog();
              getConvertLog();
              GetInboxMessage();
              GetTReq();
              GetValidKey();
              GetWrongKey();
              getSentMessages();
            } else {
              setUser([]);
              setIsMember(false);
              setDataIsGet(true);
              setDailyPending({});
              setWeeklyPending({});
              setSpecialPending({});
              setTReqNotif({ count: 0 });
              setUnreadCount(0);
            }
          });
        }
      };

      const handleChainChanged = (_hexChainId) => {
        if (parseInt(_hexChainId) !== 1) {
          alert(
            "Your network is not on Ethereum Mainnet, Change your network and connect again. "
          );
          disconnect();
        }
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  if (isConnected && !dataIsGet) {
    return <>{/* <Loading /> */}</>;
  }
  if (
    homePage &&
    !keysPage &&
    !questsPage &&
    !messagesPage &&
    !inviteKeysPage &&
    !usersPage &&
    !holdersPage
  ) {
    return (
      <>
        <Menu
          location={"home"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleKeysPage={handleKeysPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleInviteKeysPage={handleInviteKeysPage}
          handleUsersPage={handleUsersPage}
          handleHoldersPage={handleHoldersPage}
          refreshAll={refreshAll}
          tReqCount={tReqNotif.count}
          unreadCount={unreadCount}
          reviewCount={
            dailyPending.length + weeklyPending.length + specialPending.length
          }
        />
        <Home /> <Footer />
      </>
    );
  }
  if (
    !homePage &&
    keysPage &&
    !questsPage &&
    !messagesPage &&
    !inviteKeysPage &&
    !usersPage &&
    !holdersPage
  ) {
    return (
      <>
        <Menu
          location={"keyspage"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleKeysPage={handleKeysPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleInviteKeysPage={handleInviteKeysPage}
          handleUsersPage={handleUsersPage}
          handleHoldersPage={handleHoldersPage}
          refreshAll={refreshAll}
          tReqCount={tReqNotif.count}
          unreadCount={unreadCount}
          reviewCount={
            dailyPending.length + weeklyPending.length + specialPending.length
          }
        />
        <Keys
          validKey={validKey}
          wrongKey={wrongKey}
          wtoT={wtoT}
          keys={keys}
          refreshAll={refreshAll}
        />{" "}
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !keysPage &&
    questsPage &&
    !messagesPage &&
    !inviteKeysPage &&
    !usersPage &&
    !holdersPage
  ) {
    return (
      <>
        <Menu
          location={"quests"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleKeysPage={handleKeysPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleInviteKeysPage={handleInviteKeysPage}
          handleUsersPage={handleUsersPage}
          handleHoldersPage={handleHoldersPage}
          refreshAll={refreshAll}
          tReqCount={tReqNotif.count}
          unreadCount={unreadCount}
          reviewCount={
            dailyPending.length + weeklyPending.length + specialPending.length
          }
        />
        <Quests
          wtoT={wtoT}
          dailyPending={dailyPending}
          weeklyPending={weeklyPending}
          specialPending={specialPending}
          dailyQuests={dailyQuests}
          weeklyQuests={weeklyQuests}
          specialQuests={specialQuests}
          refreshAll={refreshAll}
        />{" "}
        <Footer />
      </>
    );
  }

  if (
    !homePage &&
    !keysPage &&
    !questsPage &&
    messagesPage &&
    !inviteKeysPage &&
    !usersPage &&
    !holdersPage
  ) {
    return (
      <>
        <Menu
          location={"messages"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleKeysPage={handleKeysPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleInviteKeysPage={handleInviteKeysPage}
          handleUsersPage={handleUsersPage}
          handleHoldersPage={handleHoldersPage}
          refreshAll={refreshAll}
          tReqCount={tReqNotif.count}
          unreadCount={unreadCount}
          reviewCount={
            dailyPending.length + weeklyPending.length + specialPending.length
          }
        />
        <Messages
          inboxMessage={inboxMessage}
          refreshAll={refreshAll}
          broadcasts={broadcasts}
          wtoT={wtoT}
          broadcastStatus={broadcastStatus}
          unreadCount={unreadCount}
          SentMessages={sentMessages}
        />{" "}
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !keysPage &&
    !questsPage &&
    !messagesPage &&
    inviteKeysPage &&
    !usersPage &&
    !holdersPage
  ) {
    return (
      <>
        <Menu
          location={"invitepage"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleKeysPage={handleKeysPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleInviteKeysPage={handleInviteKeysPage}
          handleUsersPage={handleUsersPage}
          handleHoldersPage={handleHoldersPage}
          refreshAll={refreshAll}
          tReqCount={tReqNotif.count}
          unreadCount={unreadCount}
          reviewCount={
            dailyPending.length + weeklyPending.length + specialPending.length
          }
        />
        <Invite refreshAll={refreshAll} inviteKeys={inviteKeys} wtoT={wtoT} />{" "}
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !keysPage &&
    !questsPage &&
    !messagesPage &&
    !inviteKeysPage &&
    usersPage &&
    !holdersPage
  ) {
    return (
      <>
        <Menu
          location={"userspage"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleKeysPage={handleKeysPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleInviteKeysPage={handleInviteKeysPage}
          handleUsersPage={handleUsersPage}
          handleHoldersPage={handleHoldersPage}
          refreshAll={refreshAll}
          tReqCount={tReqNotif.count}
          unreadCount={unreadCount}
          reviewCount={
            dailyPending.length + weeklyPending.length + specialPending.length
          }
        />
        <Users
          wtoT={wtoT}
          holders={holders}
          usersList={users}
          refreshAll={refreshAll}
          notif={notif}
          tReq={tReq}
          validKey={validKey}
          wrongKey={wrongKey}
          SentMessages={sentMessages}
          tReqNotif={tReqNotif}
          inboxMessage={inboxMessage}
          dailyNotif={dailyNotif}
          weeklyNotif={weeklyNotif}
          specialNotif={specialNotif}
          userLog={userLog}
          convertLog={convertLog}
        />{" "}
        <Footer />
      </>
    );
  }
  if (
    !homePage &&
    !keysPage &&
    !questsPage &&
    !messagesPage &&
    !inviteKeysPage &&
    !usersPage &&
    holdersPage
  ) {
    return (
      <>
        <Menu
          location={"holderspage"}
          isConnected={isConnected}
          isMember={isMember}
          user={user}
          handdleconnect={handleconnect}
          handleHomePage={handleHomePage}
          key={user}
          handleKeysPage={handleKeysPage}
          handleQuestsPage={handleQuestsPage}
          handleMessagesPage={handleMessagesPage}
          handleInviteKeysPage={handleInviteKeysPage}
          handleUsersPage={handleUsersPage}
          handleHoldersPage={handleHoldersPage}
          refreshAll={refreshAll}
          tReqCount={tReqNotif.count}
          unreadCount={unreadCount}
          reviewCount={
            dailyPending.length + weeklyPending.length + specialPending.length
          }
        />
        <Holders
          usersList={users}
          holders={holders}
          wtoT={wtoT}
          refreshAll={refreshAll}
          library={library}
        />{" "}
        <Footer />
      </>
    );
  }
};

export default Main;
