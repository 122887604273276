import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <nav className="navbar fixed-bottom bg-black border-up">
          <div className="container-fluid justify-content-end align-items-center">
            <a
              className="navbar-brand text-white"
              href="https://t.me/AkuAku369"
              target="_blank"
            >
              <i className="bi bi-telegram fs-5 text-white"></i>
            </a>
            <a
              className="navbar-brand text-white"
              href="https://twitter.com/AkuAku369"
              target="_blank"
            >
              <i className="bi bi-twitter fs-5 text-white"></i>
            </a>

            {/* <a className="navbar-brand text-white" href="#">
              <i className="bi bi-question-circle-fill fs-5 text-white"></i>
            </a> */}
            {/* <a className="navbar-brand text-white" href="#">
              <i className="bi bi-shop fs-5 text-white"></i>
            </a> */}
          </div>
        </nav>
      </>
    );
  }
}

export default Footer;
