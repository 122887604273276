import React, { Component } from "react";
import KeyItem from "./KeyItem";

class Keys extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    newKey: { keyword: "", xp: 3, score: 1, capacity: 69 },
    search: "",
  };
  setInputSearch = (e) => {
    const { name, value } = e.target;

    this.setState(() => ({
      search: value,
    }));
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      newKey: {
        ...prevState.newKey,
        [name]: value,
      },
    }));
  };
  checkKeyExist = async (k) => {
    const get = await fetch("https://managerapi.the369.xyz/checkKeyExist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        key: k,
      }),
    }).then((res) =>
      res.json().then((data) => {
        return data;
      })
    );
    return get;
  };
  handleAddKey = async () => {
    document.getElementById("sub-key-err").innerHTML = "";
    document.getElementById("sub-key-suc").innerHTML = "";
    const k = this.state.newKey;

    const res = await this.checkKeyExist(
      k.keyword.toLowerCase().replaceAll(" ", "")
    );

    if (res.length === 0) {
      this.addKey(
        k.keyword.toLowerCase().replaceAll(" ", ""),
        parseInt(k.xp),
        parseInt(k.score),
        parseInt(k.capacity)
      );
    } else {
      document.getElementById("sub-key-err").innerHTML = "Key already exist !";
    }
  };
  addKey = async (k, xp, score, capacity) => {
    const get = await fetch("https://managerapi.the369.xyz/addKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        keyword: k,

        xp: xp,
        score: score,
        capacity: capacity,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      document.getElementById("sub-key-suc").innerHTML =
        "Key successfully added !";
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row justify-content-evenly align-self-center g-1 w-100">
              <div className="col-12 col-lg-4 p-2">
                <div className="border border-1 p-1 overflow-auto h-400 text-center d-flex flex-column">
                  <span className=" mt-3 fw-bolder fs-4">Submit keys</span>
                  <input
                    onChange={this.setInput}
                    id="keyword"
                    className="form-control form-control-sm   border-success bg-dark text-white mt-auto"
                    type="text"
                    name="keyword"
                    placeholder={`Key`}
                  />
                  <span className="d-flex mt-3">
                    <label className="mt-1 me-2 text-danger fw-bolder ms-1">
                      SCORE:
                    </label>
                    <input
                      onChange={this.setInput}
                      id="score"
                      className="form-control form-control-sm   border-success bg-dark text-white"
                      type="number"
                      name="score"
                      placeholder={`1`}
                    />
                    <label className="mt-1 me-2 text-success fw-bolder ms-1">
                      XP:
                    </label>
                    <input
                      onChange={this.setInput}
                      id="xp"
                      className="form-control form-control-sm   border-success bg-dark text-white"
                      type="number"
                      name="xp"
                      placeholder={`3`}
                    />

                    <label className="mt-1 me-2 text-warning fw-bolder ms-1">
                      CAPACITY:
                    </label>
                    <input
                      onChange={this.setInput}
                      id="capacity"
                      className="form-control form-control-sm   border-success bg-dark text-white"
                      type="number"
                      name="capacity"
                      placeholder={`69`}
                    />
                  </span>
                  <span className="d-flex flex-column mb-auto">
                    <span id="sub-key-err" className=" text-danger mt-3"></span>
                    <span
                      id="sub-key-suc"
                      className=" text-success mt-3"
                    ></span>
                    <span className=" text-danger mt-2">
                      <button
                        onClick={this.handleAddKey}
                        className="btn btn-sm btn-success"
                      >
                        ADD
                      </button>
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-2 key-log">
                <div className=" border border-1 border-success p-1 overflow-auto h-400 text-center d-flex flex-column">
                  <span className=" mt-3 fw-bolder fs-4">Valid keys</span>
                  <div className="row justify-content-center w-100 p-2">
                    {this.props.validKey.map((item) => {
                      return (
                        <>
                          <div className="col-6 col-sm-4 col-md-3  p-2">
                            <div className="border border-1 p-1 text-success d-flex flex-column">
                              {item.keyword}{" "}
                              <span className="fs-8 text-muted">
                                {this.timeGenarator(item.Time)}
                              </span>
                              <a
                                className="not-underline text-white"
                                href={`https://twitter.com/${
                                  this.props.wtoT[item.user]
                                }`}
                                target="_blank"
                              >
                                {this.props.wtoT[item.user]}
                              </a>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-2 key-log">
                <div className="border border-1 border-danger p-1 overflow-auto h-400 text-center d-flex flex-column">
                  <span className=" mt-3 fw-bolder fs-4">Wrong keys</span>
                  <div className="row justify-content-evenly w-100 p-2">
                    {this.props.wrongKey.map((item) => {
                      return (
                        <>
                          <div className="col-6 col-sm-4 col-md-3  p-2">
                            <div className="border border-1 p-1 text-danger d-flex flex-column">
                              {item.keyword}{" "}
                              <span className="fs-8 text-muted">
                                {this.timeGenarator(item.Time)}
                              </span>
                              <a
                                className="not-underline text-white"
                                href={`https://twitter.com/${
                                  this.props.wtoT[item.user]
                                }`}
                                target="_blank"
                              >
                                {this.props.wtoT[item.user]}
                              </a>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12  p-2">
                <div className="border border-1 p-1 overflow-auto h-300n">
                  <div className="row justify-content-evenly w-100 p-2">
                    <div className="col-12">
                      <input
                        onChange={this.setInputSearch}
                        id="search"
                        className="form-control form-control-sm m-2  border-success bg-dark text-white"
                        type="text"
                        name="search"
                        placeholder={`Search By key`}
                      />
                    </div>
                    {this.props.keys.map((item) => {
                      if (this.state.search === "") {
                        return (
                          <>
                            <KeyItem
                              refreshAll={this.props.refreshAll}
                              item={item}
                            />
                          </>
                        );
                      } else {
                        if (
                          item.keyword
                            .toLowerCase()
                            .search(`${this.state.search.toLowerCase()}`) !== -1
                        ) {
                          return (
                            <>
                              <KeyItem
                                refreshAll={this.props.refreshAll}
                                item={item}
                              />
                            </>
                          );
                        } else {
                          return <></>;
                        }
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Keys;
