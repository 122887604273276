import React, { Component } from "react";
class QuestPending extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    msg: this.props.item.msg,
  };
  updateDailyQuestPass = async (id, status, msg) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/updateDailyQuestPass",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          DQP_ID: id,
          status: status,
          msg: msg,
        }),
      }
    ).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  updateWeeklyQuestPass = async (id, status, msg) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/updateWeeklyQuestPass",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          WQP_ID: id,
          status: status,
          msg: msg,
        }),
      }
    ).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  updateSpecialQuestPass = async (id, status, msg) => {
    const get = await fetch(
      "https://managerapi.the369.xyz/updateSpecialQuestPass",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          SQP_ID: id,
          status: status,
          msg: msg,
        }),
      }
    ).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something Wrong");
    }
  };
  addScoreXp = async (w, xp, score) => {
    const get = await fetch("https://managerapi.the369.xyz/addScoreXp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,

        xp: xp,
        score: score,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      return true;
    }
    if (get.status === "failed") {
      alert("Something wrong");
      return false;
    }
  };
  setInputMSG = (e) => {
    const { name, value } = e.target;

    this.setState(() => ({
      msg: value,
    }));
  };
  confirmQuest = async () => {
    const res = this.addScoreXp(
      this.props.item.user,
      this.props.item.xp,
      this.props.item.score
    );
    if (res) {
      if (this.props.type === "daily") {
        this.updateDailyQuestPass(
          this.props.item.DQP_ID,
          "passed",
          this.state.msg
        );
      }
      if (this.props.type === "weekly") {
        this.updateWeeklyQuestPass(
          this.props.item.WQP_ID,
          "passed",
          this.state.msg
        );
      }
      if (this.props.type === "special") {
        this.updateSpecialQuestPass(
          this.props.item.SQP_ID,
          "passed",
          this.state.msg
        );
      }
    } else {
      alert("Something wrong");
    }
  };
  rejectQuest = () => {
    if (this.props.type === "daily") {
      this.updateDailyQuestPass(this.props.item.DQP_ID, "fail", this.state.msg);
    }
    if (this.props.type === "weekly") {
      this.updateWeeklyQuestPass(
        this.props.item.WQP_ID,
        "fail",
        this.state.msg
      );
    }
    if (this.props.type === "special") {
      this.updateSpecialQuestPass(
        this.props.item.SQP_ID,
        "fail",
        this.state.msg
      );
    }
  };
  path = "https://api.the369.xyz/images/";
  timeGenarator = (t) => {
    const [date, time] = t.split("T");
    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");
    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <div className="row justify-content-evenly d-flex w-100 ">
          <div className="col-5">
            <a
              className="not-underline text-white"
              href={`https://twitter.com/${
                this.props.wtoT[this.props.item.user]
              }`}
              target="_blank"
            >
              <i className="bi bi-twitter fs-6 text-info"></i>
              {this.props.wtoT[this.props.item.user]}
            </a>
            <i
              title={this.props.item.user}
              onClick={() => {
                navigator.clipboard.writeText(this.props.item.user);
              }}
              className="bi bi-wallet2 c-pointer ms-2"
            ></i>
          </div>
          <div className="col-5">{this.props.item.subject}</div>
          <div className="col-2 fs-8 text-muted pt-1">
            {this.timeGenarator(this.props.item.Time)}
          </div>
          <div className="col-12">
            <div className="bg-dark  w-100 d-flex flex-column justify-content-evenly">
              <div
                className="accordion "
                id={`moreQuestBasePending${this.props.item.type}${this.props.index}`}
              >
                <div className="accordion-item border border-0 ">
                  <h2
                    className="accordion-header "
                    id="panelsStayOpen-headingTwo"
                  >
                    <button
                      className="accordion-button bg-black text-white collapsed border border-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#moreQuestPending${this.props.item.type}${this.props.index}`}
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Show Quest
                    </button>
                  </h2>
                  <div
                    id={`moreQuestPending${this.props.item.type}${this.props.index}`}
                    className="accordion-collapse collapse bg-black"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div className="accordion-body d-flex flex-column text-white bg-dark ">
                      <span className="d-flex justify-content-evenly"></span>
                      <span className="text-start">
                        <span className="text-muted">MISSION:</span>{" "}
                        {this.props.item.message}
                      </span>
                      <div className="d-flex justify-content-evenly">
                        <span>REWARD:</span>
                        {this.props.item.score === 0 ? (
                          <></>
                        ) : (
                          <>
                            <span>POINT: {this.props.item.score}</span>
                          </>
                        )}
                        {this.props.item.xp === 0 ? (
                          <></>
                        ) : (
                          <>
                            <span>XP: {this.props.item.xp}</span>
                          </>
                        )}
                      </div>
                      {this.props.item.answer_type === "link" ? (
                        <>
                          <span>
                            <a
                              href={this.props.item.answer}
                              target="_blank"
                              className="not-underline fs-7 text-muted"
                            >
                              {`${this.props.item.answer.substring(0, 25)}...`}
                              <i className="bi bi-box-arrow-up-right ms-3 text-light"></i>
                            </a>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "img" ? (
                        <>
                          <span>
                            <a
                              href={`${this.path}${this.props.item.answer}`}
                              target="_blank"
                            >
                              <img
                                className="me-2 answer-image border-light"
                                src={`${this.path}${this.props.item.answer}`}
                              />
                            </a>
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      {this.props.item.answer_type === "text" ? (
                        <>
                          <span className="text-light">
                            Answer: {this.props.item.answer}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex justify-content-evenly">
                        <input
                          onChange={this.setInputMSG}
                          id="msg"
                          className="form-control form-control-sm m-2 w-50  border-success bg-dark text-white"
                          type="text"
                          name="msg"
                          placeholder={`Message...`}
                        />
                      </div>
                      <div className="d-flex justify-content-evenly">
                        <button
                          onClick={this.rejectQuest}
                          className="btn btn-sm btn-danger"
                        >
                          REJECT
                        </button>
                        <button
                          onClick={this.confirmQuest}
                          className="btn btn-sm btn-success"
                        >
                          CONFIRM
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QuestPending;
