import React, { Component } from "react";
class NFTUserItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {};

  render() {
    return (
      <>
        <div
          id="NFT"
          className="col-6 col-sm-4 col-md-3  col-lg-2 text-center "
        >
          <div>
            <div className="card bg-dark max-w-200 w-100 d-flex ms-auto me-auto ">
              <h5 className="card-title fs-6 fw-bolder m-0 mb-1 mt-1 text-white ">
                {/* {this.walletGenerator(this.props.item.wallet_address)} */}
              </h5>
              <img
                src={`./NFTImages/${this.props.item.token_id}.jpg`}
                className="card-img-top"
                alt="..."
              />

              <span className="badge mt-1 fs-6">
                AkuAku #{this.props.item.token_id}
              </span>
              <div className="card-body fs-6 p-0">
                <p className="card-text m-0 p-0"></p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NFTUserItem;
