import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class UserLog extends Component {
  constructor(props) {
    super(props);
  }
  state = { show: false, search: "" };
  walletGenerator = (w) => {
    let newWallet = w.split("");

    let length = newWallet.length;

    let temp =
      newWallet[0] +
      newWallet[1] +
      newWallet[2] +
      newWallet[3] +
      newWallet[4] +
      "..." +
      newWallet[length - 4] +
      newWallet[length - 3] +
      newWallet[length - 2] +
      newWallet[length - 1];

    return temp;
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleOpen = () => {
    this.setState({ show: true });
  };
  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState(() => ({
      search: value,
    }));
  };
  render() {
    return (
      <>
        <button onClick={this.handleOpen} className="btn btn-sm btn-danger">
          Users log
        </button>
        <Modal
          show={this.state.show}
          onHide={() => {
            this.handleClose();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={true}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative "> Users Log </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row  w-100 p-3">
              <div className="col-12 ">
                <input
                  onChange={this.setInput}
                  id="search"
                  className="form-control form-control-sm m-2  border-success bg-dark text-white"
                  type="text"
                  name="search"
                  placeholder={`Search By Twitter ID`}
                />
              </div>
              {this.props.userLog.map((item) => {
                if (this.state.search === "") {
                  return (
                    <>
                      <div className="col-4 col-md-3 col-lg-2 text-center p-2">
                        <div
                          className={
                            item.twitter !== "null"
                              ? " d-flex flex-column p-2 bg-black BR-5 mb-2 border border-1 h-100"
                              : "d-flex flex-column p-2 bg-black BR-5 mb-2 border border-1 border-danger h-100"
                          }
                        >
                          <span
                            onClick={() => {
                              navigator.clipboard.writeText(item.wallet);
                            }}
                            className="c-pointer"
                          >
                            {this.walletGenerator(item.wallet)}
                          </span>
                          {item.twitter !== "null" ? (
                            <>
                              <span>
                                {" "}
                                <a
                                  className="not-underline text-white"
                                  href={`https://twitter.com/${item.twitter}`}
                                  target="_blank"
                                >
                                  <i className="bi bi-twitter fs-6 text-info"></i>
                                  {item.twitter}
                                </a>{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}

                          <span className="text-muted fs-8">
                            {this.timeGenarator(item.Time)}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                } else {
                  if (
                    item.twitter
                      .toLowerCase()
                      .search(`${this.state.search.toLowerCase()}`) !== -1 &&
                    item.twitter !== "null"
                  ) {
                    return (
                      <>
                        <div className="col-4 col-md-3 col-lg-2 text-center p-2">
                          <div
                            className={
                              item.twitter !== "null"
                                ? " d-flex flex-column p-2 bg-black BR-5 mb-2 border border-1 h-100"
                                : "d-flex flex-column p-2 bg-black BR-5 mb-2 border border-1 border-danger h-100"
                            }
                          >
                            <span
                              onClick={() => {
                                navigator.clipboard.writeText(item.wallet);
                              }}
                              className="c-pointer"
                            >
                              {this.walletGenerator(item.wallet)}
                            </span>
                            {item.twitter !== "null" ? (
                              <>
                                <span>
                                  {" "}
                                  <a
                                    className="not-underline text-white"
                                    href={`https://twitter.com/${item.twitter}`}
                                    target="_blank"
                                  >
                                    <i className="bi bi-twitter fs-6 text-info"></i>
                                    {item.twitter}
                                  </a>{" "}
                                </span>
                              </>
                            ) : (
                              <></>
                            )}

                            <span className="text-muted fs-8">
                              {this.timeGenarator(item.Time)}
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  }
                }

                return null;
              })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UserLog;
