import React, { Component } from "react";

class Menu extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    user: this.props.user,
    T: "NOTSET",
    M: "NOTSET",
    Q: "NOTSET",
  };
  componentDidMount() {
    if (!("Notification" in window)) {
      console.log("Browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    this.setState({ T: this.props.tReqCount });
    this.setState({ M: this.props.unreadCount });
    this.setState({ Q: this.props.reviewCount });
  }

  showNotification(t, i, b) {
    var title = t;
    var icon = `./asset/logo/${i}.png`;
    var body = b;
    new Notification(title, { body, icon });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }
  }
  render() {
    if (this.props.unreadCount === 0 && this.state.M !== 0) {
      this.setState({ M: this.props.unreadCount });
    }
    if (this.props.reviewCount === 0 && this.state.Q !== 0) {
      this.setState({ Q: this.props.reviewCount });
    }
    if (this.props.tReqCount === 0 && this.state.T !== 0) {
      this.setState({ T: this.props.tReqCount });
    }
    if (
      this.props.unreadCount !== 0 &&
      this.props.unreadCount !== undefined &&
      this.props.unreadCount !== this.state.M &&
      this.props.isMember
    ) {
      this.setState({ M: this.props.unreadCount });
      this.showNotification(
        "NEW MESSAGE",
        "new",
        `You have ${this.props.unreadCount} unread message`
      );
    }

    if (
      this.props.reviewCount !== 0 &&
      !isNaN(this.props.reviewCount) &&
      this.props.reviewCount !== this.state.Q &&
      this.props.isMember
    ) {
      this.setState({ Q: this.props.reviewCount });

      this.showNotification(
        "NEW QUEST REVIEW",
        "quest",
        `You have ${this.props.reviewCount} quest for review`
      );
    }
    if (
      this.props.tReqCount !== 0 &&
      !isNaN(this.props.tReqCount) &&
      this.props.tReqCount !== this.state.T &&
      this.props.isMember
    ) {
      this.setState({ T: this.props.tReqCount });

      this.showNotification(
        "NEW TWITTER CHANGE REQUEST",
        "twitter",
        `You have ${this.props.tReqCount} twitter change request for review`
      );
    }

    return (
      <>
        <nav className="navbar navbar-dark bg-black fixed-top border-down">
          <div className="container-fluid d-flex justify-content-start">
            <button
              className="btn-menu"
              type="a"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
              aria-controls="offcanvasDarkNavbar"
            >
              {/* <!-- <span className="navbar-toggler-icon"></span> --> */}
              <i className="bi bi-list fs-3 text-white"></i>
            </button>

            <div className="max-h-56 ms-auto justify-content-start d-flex align-items-center">
              {/*  */}
              {this.props.isConnected ? (
                <>
                  {this.props.isMember ? (
                    <>
                      <img
                        className="profile-pic me-2"
                        width="50"
                        height="50"
                        src={`./NFTImages/0.jpg`}
                      />

                      <span
                        onClick={this.props.handleMessagesPage}
                        className={
                          this.props.location === "messages"
                            ? " z-1000 disable-link c-reg badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center position-relative"
                            : "z-1000 c-pointer badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center position-relative"
                        }
                      >
                        <i
                          className={
                            this.props.unreadCount === 0
                              ? "bi bi-envelope-open-fill fs-6"
                              : "bi bi-envelope-fill fs-6"
                          }
                        ></i>
                        {this.props.unreadCount === 0 ? (
                          ""
                        ) : (
                          <>
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                              {this.props.unreadCount}
                              <span className="visually-hidden">
                                unread messages
                              </span>
                            </span>
                          </>
                        )}
                      </span>
                      <span
                        onClick={this.props.handleQuestsPage}
                        className={
                          this.props.location === "quests"
                            ? " disable-link c-reg badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center position-relative"
                            : " c-pointer badge text-bg-dark me-1 min-h-30 justify-content-start d-flex align-items-center position-relative"
                        }
                      >
                        <i
                          className={
                            this.props.reviewCount === 0
                              ? "bi bi-lightning fs-6"
                              : "bi bi-lightning fs-6 text-danger"
                          }
                        ></i>
                        {this.props.reviewCount === 0 ? (
                          ""
                        ) : (
                          <>
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                              {this.props.reviewCount}
                              <span className="visually-hidden">
                                unread messages
                              </span>
                            </span>
                          </>
                        )}
                      </span>
                      <span
                        onClick={this.props.refreshAll}
                        className="c-pointer"
                      >
                        <i className="bi bi-arrow-clockwise fs-5 text-warning "></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="fw-bolder fs-5 text-danger">
                        You don't have access
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    onClick={this.props.handdleconnect}
                    className="btn-s bg-red align-self-start ms-3 mt-3"
                  >
                    Connect Wallet
                  </button>
                </>
              )}

              {/*   */}
            </div>
            <div
              className="offcanvas offcanvas-start bg-black op-8 border border-0"
              tabIndex="-1"
              id="offcanvasDarkNavbar"
              aria-labelledby="offcanvasDarkNavbarLabel"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasDarkNavbar"
            >
              <div className="offcanvas-header text-white max-h-56">
                {/* <!-- <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button> --> */}
              </div>
              <div className="offcanvas-body mt-3">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li className="nav-item">
                    <button
                      className={
                        this.props.location === "home"
                          ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                          : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                      }
                      aria-current="page"
                      onClick={this.props.handleHomePage}
                    >
                      <i
                        className={
                          this.props.location === "home"
                            ? "fs-3 bi-house  navbar-item-custom nav-active"
                            : "fs-3 bi-house btn-369 bg-dark navbar-item-custom"
                        }
                      ></i>
                      <span className="ms-3 text-muted">Home</span>
                    </button>
                  </li>
                  {this.props.isConnected && this.props.isMember ? (
                    <>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "userspage"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleUsersPage}
                        >
                          <i
                            className={
                              this.props.location === "userspage"
                                ? "fs-3 bi-bar-chart-line  navbar-item-custom nav-active position-relative"
                                : "fs-3 bi-bar-chart-line btn-369 bg-dark navbar-item-custom position-relative"
                            }
                          >
                            {this.props.tReqCount !== 0 ? (
                              <>
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger fs-8 mt-2">
                                  {this.props.tReqCount}
                                  <span className="visually-hidden">
                                    unread messages
                                  </span>
                                </span>
                              </>
                            ) : (
                              <></>
                            )}
                          </i>
                          <span className="ms-3 text-muted">Users</span>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "quests"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleQuestsPage}
                        >
                          <i
                            className={
                              this.props.location === "quests"
                                ? "fs-3 bi-lightning  navbar-item-custom nav-active"
                                : "fs-3 bi-lightning btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Quests</span>
                        </button>
                      </li>

                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "messages"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleMessagesPage}
                        >
                          <i
                            className={
                              this.props.location === "messages"
                                ? "fs-3 bi-chat-text  navbar-item-custom nav-active"
                                : "fs-3 bi-chat-text btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Messages</span>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "keyspage"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleKeysPage}
                        >
                          <i
                            className={
                              this.props.location === "keyspage"
                                ? "fs-3 bi-key  navbar-item-custom nav-active"
                                : "fs-3 bi-key btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Point Keys</span>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "invitepage"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleInviteKeysPage}
                        >
                          <i
                            className={
                              this.props.location === "invitepage"
                                ? "fs-3 bi-person-add  navbar-item-custom nav-active"
                                : "fs-3 bi-person-add btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Invite Keys</span>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className={
                            this.props.location === "holderspage"
                              ? "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent c-reg disable-link"
                              : "nav-link px-sm-0 px-2 py-1 justify-content-start d-flex align-items-center btn btn-transparent"
                          }
                          aria-current="page"
                          onClick={this.props.handleHoldersPage}
                        >
                          <i
                            className={
                              this.props.location === "holderspage"
                                ? "fs-3 bi-bookmark-check  navbar-item-custom nav-active"
                                : "fs-3 bi-bookmark-check btn-369 bg-dark navbar-item-custom"
                            }
                          ></i>
                          <span className="ms-3 text-muted">Holders</span>
                        </button>
                      </li>
                    </>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Menu;
