import React, { Component } from "react";
import BroadcastItem from "./BroadcastItem";
import InboxMessageItem from "./InboxMessageItem";
import MessageItemWithTwitter from "./MessageItemWithTwitter";
class Messages extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    sendMessage: { subject: "", message: "", sender: "", link_title: "" },
    inbox: true,
  };
  changeState = () => {
    if (this.state.inbox) {
      this.setState({ inbox: false });
    } else {
      this.setState({ inbox: true });
    }
  };
  setInputsendMessage = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      sendMessage: {
        ...prevState.sendMessage,
        [name]: value,
      },
    }));
  };
  sendBroadcast = async (s, m, sender, l) => {
    const get = await fetch("https://managerapi.the369.xyz/sendBroadcast", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        subject: s,
        message: m,
        sender: sender,

        link_title: l,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      // alert("Successfully changed.");
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  handleSendMessage = () => {
    const SM = this.state.sendMessage;

    this.sendBroadcast(
      SM.subject,
      SM.message,
      SM.sender,

      SM.link_title
    );
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <div className="min-vh-100 work d-flex justify-content-center align-self-center">
          <div className="min-h-work d-flex justify-content-center align-self-center w-100">
            <div className="row justify-content-evenly  g-1 w-100">
              <div className="col-12 col-lg-5 p-2">
                <div className="border border-1 overflow-auto h-300n p-1">
                  <div className=" d-flex flex-column">
                    <span className="ms-2">Send Broadcast</span>
                    <input
                      id="sender"
                      className="form-control form-control-sm ms-2 w-300 border-success bg-dark text-white mt-1 "
                      type="text"
                      placeholder="sender"
                      onChange={this.setInputsendMessage}
                      name="sender"
                    />
                    <input
                      id="subject"
                      className="form-control form-control-sm ms-2 w-300 border-success bg-dark text-white mt-1 "
                      type="text"
                      placeholder="subject"
                      onChange={this.setInputsendMessage}
                      name="subject"
                    />
                    <textarea
                      type="text"
                      className=" form-control form-control-xl bg-dark text-white border-success form-signup w-80 ms-2 mt-1"
                      id="message"
                      onChange={this.setInputsendMessage}
                      name="message"
                      placeholder="Message..."
                      required
                    ></textarea>
                    <input
                      id="link_title"
                      className="form-control form-control-sm ms-2 w-300 border-success bg-dark text-white mt-1 "
                      type="text"
                      placeholder="Link_title (if don't have link let it empty)"
                      onChange={this.setInputsendMessage}
                      name="link_title"
                    />
                    <span className="fs-6 text-success" id="suc"></span>
                    <span className="fs-6 text-danger" id="err"></span>
                    <button
                      id="btn"
                      onClick={this.handleSendMessage}
                      className="btn-s bg-green align-self-start ms-3 mt-3 fs-6"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-7 p-2">
                <div className="border border-1 overflow-auto h-300n p-1">
                  <div className="row justify-content-evenly d-flex w-100 ms-1">
                    <div className="col-12  p-2">
                      <div className=" p-1 text-white d-flex  ">
                        <span className="col-2 right-border text-start px-2 fs-8">
                          Subject
                        </span>
                        <span className="col-5 right-border text-start px-2 fs-8">
                          Message
                        </span>
                        <span className="col-2 right-border text-start px-2 fs-8">
                          Sender
                        </span>
                        <span className="col-1 right-border text-start px-2 fs-8">
                          Status
                        </span>
                        <span className="col-1 right-border text-start px-2 fs-8">
                          Date
                        </span>
                        <span className="col-1  text-start px-2 fs-8">
                          Actions
                        </span>
                      </div>
                    </div>

                    {this.props.broadcasts.map((item) => {
                      return (
                        <>
                          <div className="col-12  p-2">
                            <div className="border border-1 p-1 text-white d-flex ">
                              <BroadcastItem
                                wtoT={this.props.wtoT}
                                broadcastStatus={this.props.broadcastStatus}
                                refreshAll={this.props.refreshAll}
                                item={item}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              {this.state.inbox ? (
                <>
                  <div className="col-12 text-center">
                    <button className="btn btn-sm btn-transparent text-white  nav-active c-reg">
                      InBox
                    </button>
                    <button
                      onClick={this.changeState}
                      className="btn btn-sm btn-transparent text-white border border-1 me-2"
                    >
                      Sent Box
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-12 text-center">
                    <button
                      onClick={this.changeState}
                      className="btn btn-sm btn-transparent text-white border border-1"
                    >
                      InBox
                    </button>
                    <button className="btn btn-sm btn-transparent text-white  me-2 nav-active c-reg">
                      Sent Box
                    </button>
                  </div>
                </>
              )}

              {this.state.inbox ? (
                <>
                  {/* Message Users send to us */}
                  <div className="col-12 p-2">
                    <div className="border border-1 overflow-auto h-400n p-1">
                      <div className="row justify-content-evenly d-flex w-100 ms-1">
                        <div className="col-12  p-2">
                          <div className=" p-1 text-white d-flex  ">
                            <span className="col-2 right-border text-start px-2 fs-8">
                              Subject
                            </span>
                            <span className="col-5 right-border text-start px-2 fs-8">
                              Message
                            </span>
                            <span className="col-1 right-border text-start px-2 fs-8">
                              receiver
                            </span>
                            <span className="col-1 right-border text-start px-2 fs-8">
                              Sender
                            </span>
                            <span className="col-1 right-border text-start px-2 fs-8">
                              Status
                            </span>
                            <span className="col-1 right-border text-start px-2 fs-8">
                              Date
                            </span>
                            <span className="col-1  text-start px-2 fs-8">
                              Actions
                            </span>
                          </div>
                        </div>
                        {this.props.unreadCount !== 0 ? (
                          <>
                            <div className="col-12 text-center fw-bold text-danger">
                              {this.props.unreadCount} Unread Message
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        {this.props.inboxMessage.map((item) => {
                          return (
                            <>
                              <div className="col-12  p-2">
                                <div className="border border-1 p-1 text-white d-flex ">
                                  <InboxMessageItem
                                    wtoT={this.props.wtoT}
                                    refreshAll={this.props.refreshAll}
                                    item={item}
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {/* Message We Send */}
                  <div className="col-12 p-2">
                    <div className="border border-1 overflow-auto h-400n p-1">
                      <div className="row justify-content-evenly d-flex w-100 ms-1">
                        <div className="col-12  p-2">
                          <div className=" p-1 text-white d-flex ">
                            <span className="col-2 right-border text-start px-2 fs-8">
                              Subject
                            </span>
                            <span className="col-5 right-border text-start px-2 fs-8">
                              Message
                            </span>
                            <span className="col-1 right-border text-start px-2 fs-8">
                              Receiver
                            </span>
                            <span className="col-1 right-border text-start px-2 fs-8">
                              Sender
                            </span>
                            <span className="col-1 right-border text-start px-2 fs-8">
                              Status
                            </span>
                            <span className="col-1 right-border text-start px-2 fs-8">
                              Date
                            </span>
                            <span className="col-1  text-start px-2 fs-8">
                              Actions
                            </span>
                          </div>
                        </div>

                        {this.props.SentMessages.map((item) => {
                          return (
                            <>
                              <div className="col-12  p-2">
                                <div className="border border-1 p-1 text-white d-flex w-100 ">
                                  <MessageItemWithTwitter
                                    refreshAll={this.props.refreshAll}
                                    item={item}
                                    wtoT={this.props.wtoT}
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Messages;
