import React, { Component } from "react";

class NotifItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {};

  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  deleteNotif = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/deleteNotif", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        N_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  removeScoreXp = async (w, xp, score) => {
    const get = await fetch("https://managerapi.the369.xyz/removeScoreXp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,

        xp: xp,
        score: score,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  handleDeleteNotif = () => {
    let text =
      "Delete Notificatioan\nYou can't restore action\nEither OK or Cancel.";
    if (window.confirm(text) == true) {
      this.deleteNotif(this.props.item.N_ID);
      if (this.props.item.xp === 0 && this.props.item.score === 0) {
      } else {
        this.removeScoreXp(
          this.props.item.user,
          parseInt(this.props.item.xp),
          parseInt(this.props.item.score)
        );
      }
    }
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <div className="col-12  d-flex justify-content-start  align-self-center mb-1">
          <div className="notification d-flex flex-column  w-100 position-relative">
            <span className="fw-bolder fs-6 text-start">
              {this.props.item.subject}{" "}
              <span className="fs-8 text-muted ms-2">
                {this.timeGenarator(this.props.item.Time)}
              </span>
              <span className="float-end">
                <i
                  onClick={this.handleDeleteNotif}
                  title="Delete"
                  className="bi bi-trash3 fs-6 text-danger c-pointer me-2"
                ></i>
              </span>
            </span>
            <span className="my-2 fs-6 text-start">
              {this.props.item.message}
            </span>
            {this.props.item.xp !== 0 || this.props.item.score !== 0 ? (
              <>
                <span className="reward-notif d-flex justify-content-between fs-7">
                  <span className="fw-bolder align-self-center ">Rewards:</span>{" "}
                  {this.props.item.xp !== 0 ? (
                    <>
                      <span className="ms-2 p-1 bg-dark rounded">
                        {this.props.item.xp}{" "}
                        <img
                          className=""
                          width="20"
                          height="20"
                          src="./asset/Menu/xp.png"
                        />{" "}
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.item.score !== 0 ? (
                    <>
                      {this.props.item.score === 1 ? (
                        <>
                          <span className="ms-2 p-1 bg-dark rounded text-success fw-bold fs-8">
                            {this.props.item.score} POINT
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="ms-2 p-1 bg-dark rounded text-success fw-bold fs-8">
                            {this.props.item.score} POINTS
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default NotifItem;
