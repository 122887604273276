import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class KeyItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    editKey: {
      keyword: this.props.item.keyword,
      xp: this.props.item.xp,
      score: this.props.item.point,
      capacity: this.props.item.capacity,
    },
    editModal: false,
  };
  EditKey = async (id, xp, point, capacity, k) => {
    const get = await fetch("https://managerapi.the369.xyz/EditKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        K_ID: id,
        xp: xp,
        point: point,
        capacity: capacity,
        keyword: k,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      setTimeout(() => {
        this.handleClose();
      }, 500);
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  handleClose = () => {
    this.setState({ editModal: false });
  };
  handleOpen = () => {
    this.setState({ editModal: true });
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      editKey: {
        ...prevState.editKey,
        [name]: value,
      },
    }));
  };
  handleEditKey = () => {
    const EK = this.state.editKey;

    this.EditKey(
      this.props.item.K_ID,
      parseInt(EK.xp),
      parseInt(EK.score),
      parseInt(EK.capacity),
      EK.keyword.toLowerCase().replaceAll(" ", "")
    );
  };
  deleteKey = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/deleteKey", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        K_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
      alert("Something wrong!");
    }
  };
  handleDeleteKey = () => {
    let text = "Delete Key\nYou can't restore action\nEither OK or Cancel.";
    if (window.confirm(text) == true) {
      this.deleteKey(this.props.item.K_ID);
    }
  };
  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <div className="col-6 col-sm-4 col-md-3 col-lg-2 p-2">
          <div className="border border-1 p-1 text-white d-flex flex-column">
            <span className="d-flex fw-bold">
              {this.props.item.keyword}{" "}
              <i
                onClick={this.handleDeleteKey}
                title="Delete"
                className="bi bi-trash3 fs-6 text-danger c-pointer me-2 ms-auto"
              ></i>
              <i
                onClick={this.handleOpen}
                title="Edit"
                className="bi bi-pencil fs-6 text-white c-pointer "
              ></i>
            </span>
            <span className="d-flex justify-content-evenly fs-7">
              {this.props.item.point !== 0 ? (
                <>
                  <span>POINT: {this.props.item.point}</span>
                </>
              ) : (
                <></>
              )}
              {this.props.item.xp !== 0 ? (
                <>
                  <span>XP: {this.props.item.xp}</span>
                </>
              ) : (
                <></>
              )}

              <span className="badge p-1 bg-dark text-danger">
                {" "}
                {this.props.item.capacity}
              </span>
            </span>

            <span className="fs-8 text-muted">
              {this.timeGenarator(this.props.item.Time)}
            </span>
          </div>
        </div>
        <Modal
          show={this.state.editModal}
          onHide={() => {
            this.handleClose();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                Edit Keys: {this.props.item.keyword}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="border border-1 p-1 overflow-auto h-400 text-center d-flex flex-column">
              <span className=" mt-3 fw-bolder fs-4">Edit key</span>
              <input
                onChange={this.setInput}
                id="keyword"
                className="form-control form-control-sm   border-success bg-dark text-white mt-auto"
                type="text"
                name="keyword"
                placeholder={`${this.props.item.keyword}`}
              />
              <span className="d-flex mt-3">
                <label className="mt-1 me-2 text-danger fw-bolder ms-1">
                  SCORE:
                </label>
                <input
                  onChange={this.setInput}
                  id="score"
                  className="form-control form-control-sm   border-success bg-dark text-white"
                  type="number"
                  name="score"
                  placeholder={`${this.props.item.point}`}
                />
                <label className="mt-1 me-2 text-success fw-bolder ms-1">
                  XP:
                </label>
                <input
                  onChange={this.setInput}
                  id="xp"
                  className="form-control form-control-sm   border-success bg-dark text-white"
                  type="number"
                  name="xp"
                  placeholder={`${this.props.item.xp}`}
                />

                <label className="mt-1 me-2 text-warning fw-bolder ms-1">
                  CAPACITY:
                </label>
                <input
                  onChange={this.setInput}
                  id="capacity"
                  className="form-control form-control-sm   border-success bg-dark text-white"
                  type="number"
                  name="capacity"
                  placeholder={`${this.props.item.capacity}`}
                />
              </span>
              <span className="d-flex flex-column mb-auto">
                <span id="sub-key-err" className=" text-danger mt-3"></span>
                <span id="sub-key-suc" className=" text-success mt-3"></span>
                <span className=" text-danger mt-2">
                  <button
                    onClick={this.handleEditKey}
                    className="btn btn-sm btn-success"
                  >
                    EDIT
                  </button>
                </span>
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default KeyItem;
