import React, { Component } from "react";
import Linkify from "react-linkify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
class MessageItem extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    editModal: false,
    editMessage: {
      subject: this.props.item.subject,
      message: this.props.item.message,
      sender: this.props.item.sender,
      link_title: this.props.item.link_title,
    },
  };
  setInputEditMessage = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      editMessage: {
        ...prevState.editMessage,
        [name]: value,
      },
    }));
  };
  handleEditMessage = () => {
    const EM = this.state.editMessage;

    this.EditMessage(
      this.props.item.UM_ID,
      EM.subject,
      EM.message,
      EM.sender,
      EM.link_title
    );
  };
  handleClose = () => {
    this.setState({ editModal: false });
  };
  handleOpen = () => {
    this.setState({ editModal: true });
  };
  EditMessage = async (id, s, m, sender, l) => {
    const get = await fetch("https://managerapi.the369.xyz/EditMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        UM_ID: id,
        subject: s,
        message: m,
        sender: sender,
        link_title: l,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      alert("successfully Changed.");
      this.handleClose();
    }
    if (get.status === "failed") {
    }
  };
  RestoreMessage = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/RestoreMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        UM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  DeleteMessage = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/DeleteMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        UM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  UnreadMessage = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/UnreadMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        UM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  ReadMessage = async (id) => {
    const get = await fetch("https://managerapi.the369.xyz/ReadMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        UM_ID: id,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
    }
    if (get.status === "failed") {
    }
  };
  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <button
          id="refresh"
          className="d-none"
          onClick={this.props.refreshAll}
        ></button>
        <span className="col-2 right-border text-start px-2">
          {this.props.item.subject}
        </span>
        <span className="col-5 right-border text-start px-2 new-line">
          <Linkify
            componentDecorator={(decoratedHref) => (
              <a
                target="blank"
                href={decoratedHref}
                className=" not-underline msg-link bg-info mt-1"
              >
                {this.props.item.link_title}
              </a>
            )}
          >
            {this.props.item.message}
          </Linkify>
        </span>
        <span className="col-2 right-border text-start px-2">
          {this.props.item.sender}
        </span>
        <span className="col-1 right-border text-center px-2">
          {this.props.item.status === "read" ? (
            <>
              <i
                onClick={() => {
                  this.UnreadMessage(this.props.item.UM_ID);
                }}
                title="Read"
                className="bi bi-check2-all text-success fs-5 c-pointer"
              ></i>
            </>
          ) : (
            <>
              <i
                onClick={() => {
                  this.ReadMessage(this.props.item.UM_ID);
                }}
                title="Unread"
                className="bi bi-check2 text-danger fs-5 c-pointer"
              ></i>
            </>
          )}
        </span>
        <span className="col-1 right-border  text-start px-2 text-muted fs-8">
          {this.timeGenarator(this.props.item.Time)}
        </span>
        <span className="col-1   text-start px-2 text-center fs-8">
          {this.props.item.deleted === 1 ? (
            <>
              <i
                onClick={() => {
                  this.RestoreMessage(this.props.item.UM_ID);
                }}
                title="Restore"
                className="bi bi-arrow-clockwise fs-6 text-success c-pointer me-2 "
              ></i>
            </>
          ) : (
            <>
              <i
                onClick={() => {
                  this.DeleteMessage(this.props.item.UM_ID);
                }}
                title="Delete"
                className="bi bi-trash3 fs-6 text-danger c-pointer me-2"
              ></i>
            </>
          )}

          <i
            onClick={this.handleOpen}
            title="Edit"
            className="bi bi-pencil fs-6 text-white c-pointer "
          ></i>
        </span>

        <Modal
          show={this.state.editModal}
          onHide={() => {
            this.handleClose();
          }}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
          fullscreen={"md-down"}
          contentClassName="bg-dark"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="position-relative ">
                {" "}
                edit message : {this.props.item.subject}{" "}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 d-flex flex-column p-3">
              <input
                id="sender"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Sender :${this.props.item.sender}`}
                onChange={this.setInputEditMessage}
                name="sender"
              />
              <input
                id="subject"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Subject :${this.props.item.subject}`}
                onChange={this.setInputEditMessage}
                name="subject"
              />
              <textarea
                type="text"
                className=" form-control form-control-xl bg-dark text-white border-success form-signup ms-2"
                id="message"
                onChange={this.setInputEditMessage}
                name="message"
                placeholder={`Message :${this.props.item.message}`}
                required
              ></textarea>
              <input
                id="link_title"
                className="form-control form-control-sm m-2 w-300 border-success bg-dark text-white "
                type="text"
                placeholder={`Link Titele :${this.props.item.link_title}`}
                onChange={this.setInputEditMessage}
                name="link_title"
              />
              <button
                id="btn"
                onClick={this.handleEditMessage}
                className="btn-s bg-green align-self-start ms-3 mt-3 fs-6 mt-auto mb-5"
              >
                Edit
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleClose();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default MessageItem;
