import React, { Component } from "react";
class TReq extends Component {
  constructor(props) {
    super(props);
  }
  state = { req_msg: null };
  TReqConfirm = async (TCR_ID, req_msg) => {
    const get = await fetch("https://managerapi.the369.xyz/TReqConfirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        TCR_ID: TCR_ID,
        req_msg: req_msg,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      this.updateUserTwitter(
        this.props.item.user,
        this.props.item.new_twitter_id
      );
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  TReqReject = async (TCR_ID, req_msg) => {
    const get = await fetch("https://managerapi.the369.xyz/TReqReject", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        TCR_ID: TCR_ID,
        req_msg: req_msg,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      alert("Succsesfully changed !");
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  updateUserTwitter = async (w, t) => {
    const get = await fetch("https://managerapi.the369.xyz/updateUserTwitter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        wallet_address: w,
        Twitter_Id: t,
      }),
    }).then((res) => res.json());
    if (get.status === "done") {
      document.getElementById("refresh").click();
      alert("Succsesfully changed !");
    }
    if (get.status === "failed") {
      alert("Something wrong");
    }
  };
  setInput = (e) => {
    const { name, value } = e.target;

    this.setState(() => ({
      req_msg: value,
    }));
  };
  handleTReqReject = () => {
    this.TReqReject(this.props.item.TCR_ID, this.state.req_msg);
  };
  handleTReqConfirm = () => {
    this.TReqConfirm(this.props.item.TCR_ID, this.state.req_msg);
  };
  timeGenarator = (t) => {
    const [date, time] = t.split("T");

    const mounthList = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    const [year, mounth, d] = date.split("-");
    const [H, M, S] = time.split(":");

    var day = String(parseInt(d));
    var temp = `${day} ${mounthList[mounth]} ${year}`;
    return temp;
  };
  render() {
    return (
      <>
        <div className="col-12">
          <div className="history-item fs-7 d-flex justify-content-evenly  align-items-center ">
            <span className="history-item-child text-center w-100 ">
              <a
                className="not-underline text-white"
                href={`https://twitter.com/${this.props.item.old_twitter_id}`}
                target="_blank"
              >
                {this.props.item.old_twitter_id}
              </a>
            </span>
            <span className="history-item-child text-center w-100">
              <a
                className="not-underline text-white"
                href={`https://twitter.com/${this.props.item.new_twitter_id}`}
                target="_blank"
              >
                {this.props.item.new_twitter_id}
              </a>
            </span>
            <span
              className={
                this.props.item.status === "pending"
                  ? "history-item-child text-center text-warning w-100"
                  : this.props.item.status === "confirmed"
                  ? "history-item-child text-center text-success w-100"
                  : "history-item-child text-center text-danger w-100"
              }
            >
              {this.props.item.status}
            </span>
            <span className="history-item-child text-center w-100 p-1">
              {" "}
              <input
                onChange={this.setInput}
                id="Req_msg"
                name="req_msg"
                className="form-control  req-form w-100 border-success bg-dark text-white"
                type="text"
                placeholder={`${this.props.item.message}`}
              />
            </span>{" "}
            <span className="history-item-child text-center w-100">
              {" "}
              <i
                onClick={this.handleTReqConfirm}
                className="bi bi-check2 me-2 text-success c-pointer"
              ></i>
              <i
                onClick={this.handleTReqReject}
                className="bi bi-x-lg text-danger c-pointer"
              ></i>
            </span>{" "}
            <span className="fs-8 text-muted w-100 text-center">
              {this.timeGenarator(this.props.item.Time)}
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default TReq;
